import { fontWeightBold, fontWeightMedium, fontWeightNormal } from "@assets/styles/tokens/typography";

import React from "react";

import PropTypes from "prop-types";

import { TextWrapper } from "./theme";

const Text = ({ children, tag = "p", size = "text20", color, weight = "normal", className }) => {
    const resultWeight = {
        medium: fontWeightMedium,
        normal: fontWeightNormal,
        bold: fontWeightBold,
    }[weight];
    return (
        <TextWrapper className={className} color={color} size={size} as={tag} weight={resultWeight}>
            {children}
        </TextWrapper>
    );
};

Text.propTypes = {
    children: PropTypes.node.isRequired,
    tag: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    className: PropTypes.string,
};

export default Text;
