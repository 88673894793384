import { RED_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize16, fontSize20, fontWeightExtraBold, secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const DeleteWrapper = styled.div`
    padding-top: 72px;

    ${adaptive.minWidth.tablet} {
        padding-top: 88px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DeleteTextbox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    text-align: center;
    ${adaptive.minWidth.tablet} {
        margin-bottom: 27px;
        gap: 30px;
    }
`;
export const DeleteFormText = styled.div`
    justify-content: center;
    flex-wrap: wrap;
    display: inline;
    text-align: center;
    ${secondaryFontFamily}
    ${fontWeightExtraBold}
    ${fontSize20};
    color: ${WHITE_COLOR};
    line-height: 24px;
    a {
        color: ${RED_COLOR};
    }
`;
export const DeleteOfferWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 866px;
    margin: 40px auto 80px;
    position: relative;

    ${adaptive.minWidth.tablet} {
        margin: 56px auto 120px;
    }

    h1 {
        text-align: center;

        letter-spacing: 0.02em;

        span {
            position: relative;
            z-index: 1;
            display: inline;
            width: max-content;
            margin: auto;
            margin-left: 19px;
            letter-spacing: 1px;

            &:after {
                content: "";
                position: absolute;
                bottom: -2%;
                right: 51%;
                transform: translateX(50%);
                z-index: -1;
                width: 99%;
                height: 12px;
                background-color: ${RED_COLOR};

                ${adaptive.minWidth.tablet} {
                    height: 20px;
                }
            }
        }
    }

    .delete-list-text {
        text-align: center;
        line-height: 27px;
        ${fontSize16};
        ${secondaryFontFamily};

        ${adaptive.minWidth.tablet} {
            ${fontSize20};
        }
    }

    .delete-title {
        margin-bottom: 35px;
    }
`;
