import { GREY_COLOR, RED_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize16, fontSize40, fontWeightBold, secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const StockWrapper = styled.div`
    background-color: ${GREY_COLOR};

    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;

    height: 363px;
    ${adaptive.minWidth.tablet} {
        height: 620px;
    }
    ${adaptive.minWidth.desktop} {
        height: 425px;
    }

    .stock-emoji {
        display: inline-block;

        font-size: 40px;
        ${adaptive.minWidth.tablet} {
            font-size: 58px;
        }

        &_one {
            transform: rotate(15deg);
        }
        &_three {
            transform: rotate(18.14deg);
        }
    }

    .stock-text {
        font-size: 24px;
        ${adaptive.minWidth.tablet} {
            font-size: 40px;
        }
    }

    .stock-title {
        max-width: none;
        ${adaptive.maxWidth.tablet} {
            ${fontSize40}
        }

        margin-bottom: 8px;

        &_three {
            white-space: pre-line;
        }

        .stock-text-dedicated {
            position: relative;
            z-index: 1;

            display: inline-block;

            ${fontWeightBold}

            &:after {
                content: "";
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;

                width: 104%;
                height: 20px;

                background-color: ${RED_COLOR};
            }
        }
    }

    .stock-time {
        ${secondaryFontFamily}
        ${adaptive.maxWidth.tablet} {
            ${fontSize16}
        }
    }
`;

export const StockInnerWrapper = styled.div`
    position: relative;
    height: 100%;
    img {
        position: absolute;
        right: 0;
        bottom: 0;

        max-width: 293px;
        ${adaptive.minWidth.tablet} {
            max-width: 533px;
        }
        ${adaptive.minWidth.desktop} {
            max-width: 545px;
        }
    }
`;

export const StockBlock = styled.div`
    padding: 24px;
    ${adaptive.minWidth.tablet} {
        padding: 32px;
    }
    ${adaptive.minWidth.desktop} {
        padding: 48px;
    }
`;
