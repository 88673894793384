import { Footer } from "@organisms/footer";
import { Header } from "@organisms/header";

import React from "react";

import PropTypes from "prop-types";

const Page = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

Page.propTypes = {
    children: PropTypes.node,
};

export default Page;
