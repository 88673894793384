import StockOneImg from "@assets/image/stock1.svg";
import StockTwoImg from "@assets/image/stock2.svg";
//import StockThreeImg from "@assets/image/stock3.svg";
import StockFourImg from "@assets/image/stock4.svg";
import { Slider } from "@atoms/slider";
import { Text } from "@atoms/text";
import { Title } from "@atoms/title";
import { SliderStockWrapper } from "@molecules/slider-stock/theme";
import { Stock } from "@molecules/stock";

import React, { useMemo } from "react";

const SliderStock = () => {
    const stockOne = useMemo(() => {
        return (
            <Stock
                title={
                    <Text size="text72" className="stock-title stock-title_one">
                        Дарим кофе любого объема за 6 баллов, вступай в{" "}
                        <span className="stock-text-dedicated stock-text-dedicated_one">клуб любителей кофе!</span>
                    </Text>
                }
                /* text={
                    <Text size="text48" className="stock-text">
                        После 19:00 <span className="stock-emoji stock-emoji_one">&#129303;</span>
                    </Text>
                } */
                src={StockOneImg}
            />
        );
    }, []);

    const stockTwo = useMemo(() => {
        return (
            <Stock
                title={
                    <Text size="text72" className="stock-title stock-title_two">
                        <span className="stock-text-dedicated stock-text-dedicated_two">Скидка 33%</span> на всё меню
                        после 19:00! Вечер будет вкусным <span className="stock-emoji stock-emoji_two">&#128522;</span>
                    </Text>
                }
                src={StockTwoImg}
            />
        );
    }, []);

    const stockFour = useMemo(() => {
        return (
            <Stock
                title={
                    <Text size="text72" className="stock-title stock-title_three">
                        ЦЕЛЫЙ ЧИЗКЕЙК ЗА <span className="stock-text-dedicated stock-text-dedicated_three">1580 Р</span>
                        <br />
                    </Text>
                }
                text={
                    <Text size="text38" className="stock-text">
                        НА ВЫБОР ЧЕТЫРЕ <span className="stock-text-dedicated">ВКУСА</span>: КЛАССИЧЕСКИЙ, КАРАМЕЛЬНЫЙ
                        <br />
                        МАЛИНОВЫЙ И ШОКОЛАДНЫЙ
                    </Text>
                }
                src={StockFourImg}
            />
        );
    }, []);

    const items = [stockOne, stockTwo, stockFour];

    return (
        <SliderStockWrapper id="promo">
            <Title size="title48">Акции</Title>
            <Slider slidesPerView={1} items={items} pagination={true} />
        </SliderStockWrapper>
    );
};

export default SliderStock;
