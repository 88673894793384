import { apiMethods } from "@api/api-methods";
import { Map } from "@atoms/map";
import { Title } from "@atoms/title";
import { stores } from "@organisms/map-coffee/helpers";
import { MapCoffeeStyled, MapCoffeeWrapper } from "@organisms/map-coffee/theme";

import React, { useCallback, useEffect, useState } from "react";

import AddressSidebar from "../../molecules/address-sidebar/address-sidebar";

const MapCoffee = () => {
    const [currentAddress, setCurrentAddress] = useState({});
    const [isShowCurrentAddress, setIsShowCurrentAddress] = useState(false);

    const selectMarkerAddress = useCallback((store) => {
        setIsShowCurrentAddress(true);
        setCurrentAddress(store);
    }, []);

    return (
        <MapCoffeeWrapper id="map">
            <Title size="title48">Карта кофеен</Title>
            <MapCoffeeStyled>
                <AddressSidebar
                    storesList={stores}
                    currentAddress={currentAddress}
                    isShowCurrentAddress={isShowCurrentAddress}
                    setIsShowCurrentAddress={setIsShowCurrentAddress}
                />
                <Map storesList={stores} setMarkerAddress={selectMarkerAddress} />
            </MapCoffeeStyled>
        </MapCoffeeWrapper>
    );
};

export default MapCoffee;
