import { InputWrapper } from "@atoms/input/theme";

import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

const Input = ({
    onFocus,
    onBlur,
    placeholder = "",
    maxLength = 350,
    onChange,
    type = "text",
    value,
    isFocus = false,
}) => {
    const [localValue, setLocalValue] = useState("");
    const [isFocused, setFocused] = useState(isFocus);
    const [isActive, setSetActive] = useState(false);

    const resultValue = onChange ? value || "" : localValue;

    const handleFocus = useCallback(() => {
        setFocused(true);
        onFocus && onFocus(true);
    }, [onFocus]);

    const handleBlur = useCallback(
        (event) => {
            setFocused(false);
            setSetActive(false);
            onFocus && onFocus(false);
            onBlur && onBlur(event);
        },
        [onBlur, onFocus],
    );

    const handleChange = useCallback(
        (event) => {
            setSetActive(true);
            if (onChange) {
                onChange(event.target.value);
            } else {
                setLocalValue(event.target.value);
            }
        },
        [onChange],
    );

    return (
        <label>
            <InputWrapper
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={resultValue}
                placeholder={placeholder}
                maxLength={maxLength}
                type={type}
            />
        </label>
    );
};

Input.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    isFocus: PropTypes.bool,
};

export default Input;
