import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const SliderStockWrapper = styled.div`
    h5 {
        margin-bottom: 32px;
    }

    .swiper {
        padding-bottom: 24px;
    }

    .swiper-pagination {
        bottom: 0;
    }

    .swiper-pagination-bullet {
        width: 60px;
        height: 4px;
        ${adaptive.minWidth.tablet} {
            width: 77px;
        }

        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        border-radius: 32px;

        background-color: #646468;
        opacity: 1;

        &-active {
            background-color: ${WHITE_COLOR};
        }
    }
`;
