import SizeImg from "@assets/icons/size.svg";
import NameDrink from "@atoms/name-drink/name-drink";
import { PriceDrink } from "@atoms/price-drink";
import { Text } from "@atoms/text";
import {
    FourColumnWrapper,
    MenuListDrinkBody,
    MenuListDrinkHeader,
    MenuListDrinkRow,
    MenuListDrinkTitle,
    MenuListDrinkUnique,
    MenuListDrinkWrapper,
    OneColumnWrapper,
    ThreeColumnWrapper,
    TwoColumnWrapper,
} from "@molecules/menu-list-drink/theme";

import React from "react";

import PropTypes from "prop-types";

const MenuListDrink = ({ list }) => {
    return (
        <MenuListDrinkWrapper>
            <MenuListDrinkHeader>
                <OneColumnWrapper>
                    <Text size="text24">размер</Text>
                </OneColumnWrapper>
                <TwoColumnWrapper className="column-header">
                    <img src={SizeImg} alt="" />
                    <Text size="text24">uno</Text>
                </TwoColumnWrapper>
                <ThreeColumnWrapper className="column-header">
                    <img src={SizeImg} alt="" />
                    <Text size="text24">media</Text>
                </ThreeColumnWrapper>
                <FourColumnWrapper className="column-header">
                    <img src={SizeImg} alt="" />
                    <Text size="text24">Grande</Text>
                </FourColumnWrapper>
            </MenuListDrinkHeader>
            <MenuListDrinkBody>
                {list.map((drink, idx) => (
                    <MenuListDrinkRow key={idx}>
                        <OneColumnWrapper>
                            <MenuListDrinkTitle>
                                <NameDrink drink={drink} />

                                {drink.description && <Text size="text20">{drink.description}</Text>}
                            </MenuListDrinkTitle>
                            {drink.unique && (
                                <MenuListDrinkUnique>
                                    <Text size="text20" weight="bold">
                                        Только в Coffee bon
                                    </Text>
                                </MenuListDrinkUnique>
                            )}
                        </OneColumnWrapper>
                        <TwoColumnWrapper className="column-body-row">
                            <PriceDrink price={drink.uno} />
                        </TwoColumnWrapper>
                        <ThreeColumnWrapper className="column-body-row">
                            <PriceDrink price={drink.media} />
                        </ThreeColumnWrapper>
                        <FourColumnWrapper className="column-body-row">
                            <PriceDrink price={drink.grande} />
                        </FourColumnWrapper>
                    </MenuListDrinkRow>
                ))}
            </MenuListDrinkBody>
        </MenuListDrinkWrapper>
    );
};

MenuListDrink.propTypes = {
    list: PropTypes.array.isRequired,
};

export default MenuListDrink;
