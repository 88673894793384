import { PriceDrinkWrapper } from "@atoms/price-drink/theme";
import { Text } from "@atoms/text";

import React, { useMemo } from "react";

import PropTypes from "prop-types";

const PriceDrink = ({ price }) => {
    const priceComponent = useMemo(() => {
        if (price) {
            return (
                <>
                    <Text size="text48" weight="bold" className="price">
                        {price}
                    </Text>
                    <Text size="text24" className="currency">
                        руб
                    </Text>
                </>
            );
        } else {
            return (
                <Text size="text48" weight="bold" className="price price-empty">
                    –
                </Text>
            );
        }
    }, [price]);

    return <PriceDrinkWrapper>{priceComponent}</PriceDrinkWrapper>;
};

PriceDrink.propTypes = {
    price: PropTypes.number,
};

export default PriceDrink;
