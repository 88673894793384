import styled from "styled-components";

export const CloseBtnPopupWrapper = styled.button`
    border: none;

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;

    background: rgba(0, 0, 0, 0.45);

    margin-left: auto;
`;
