import { apiCall } from "@api/api-call";

class ApiMethods {
    async getStores() {
        return await apiCall.get(
            `hello.php?clientId=4e27d492-02aa-11ed-8db8-001e6749104a&phone=+79052083247&push_token=null`,
        );
    }
}

export const apiMethods = new ApiMethods();
