import Inst from "@assets/icons/Inst.svg";
import Tg from "@assets/icons/Telegram.svg";
import Tiktok from "@assets/icons/Tiktok.svg";
import Vk from "@assets/icons/VK.svg";

export const socialLinksList = [
    {
        src: Vk,
        link: "https://vk.com/coffee_bon",
    },
    {
        src: Tg,
        link: "https://t.me/coffee_bon",
    },
    {
        src: Tiktok,
        link: "https://vt.tiktok.com/ZSdwtv94r/",
    },
];
