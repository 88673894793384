import { SocialLnkWrapper } from "@atoms/social-link/theme";

import React from "react";
import { ReactSVG } from "react-svg";

import PropTypes from "prop-types";

const SocialLink = ({ src, link }) => {
    return (
        <SocialLnkWrapper>
            <a href={link} target="_blank" rel="noreferrer">
                <ReactSVG src={src} />
            </a>
        </SocialLnkWrapper>
    );
};

SocialLink.propTypes = {
    src: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default SocialLink;
