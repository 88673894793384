import { YELLOW_COLOR } from "@assets/styles/tokens/colors";

import styled from "styled-components";

export const SocialLnkWrapper = styled.div`
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.15);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;

    a {
        &:hover {
            svg {
                path {
                    fill: ${YELLOW_COLOR};
                }
            }
        }
    }

    svg {
        max-width: 20px;
    }

    div {
        line-height: 0;
    }
`;
