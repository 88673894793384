import { secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const DrinkTooltipWrapper = styled.div`
    p {
        line-height: 140%;
        ${secondaryFontFamily}
    }
`;
