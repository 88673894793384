import { HeaderDrawerWrapper } from "@molecules/header-drawer/theme";
import { NavigationList } from "@molecules/navigation-list";
import { SocialLinksList } from "@molecules/social-links-list";

import React from "react";

import PropTypes from "prop-types";

const HeaderDrawer = ({ handleClickNavigationItem }) => {
    return (
        <HeaderDrawerWrapper>
            <NavigationList className="fd-c" weight="bold" size="text40" handleClickItem={handleClickNavigationItem} />
            <SocialLinksList />
        </HeaderDrawerWrapper>
    );
};

HeaderDrawer.propTypes = {
    handleClickNavigationItem: PropTypes.func,
};

export default HeaderDrawer;
