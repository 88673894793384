import Close from "@assets/icons/close.svg";
import { CloseBtnPopupWrapper } from "@atoms/close-btn-popup/theme";

import React from "react";

import PropTypes from "prop-types";

const CloseBtnPopup = ({ clickHandler }) => {
    return (
        <CloseBtnPopupWrapper onClick={clickHandler}>
            <img src={Close} alt="" />
        </CloseBtnPopupWrapper>
    );
};

CloseBtnPopup.propTypes = {
    clickHandler: PropTypes.func.isRequired,
};

export default CloseBtnPopup;
