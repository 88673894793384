import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const MenuWrapper = styled.div`
    padding-bottom: 160px;
    ${adaptive.minWidth.tablet} {
        padding-bottom: 240px;
    }
`;
