import { SliderWrapper } from "@atoms/slider/theme";
import { Text } from "@atoms/text";

import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const defaultAutoplayOption = {
    delay: 4500,
    disableOnInteraction: false,
};

const Slider = ({
    items,
    slidesPerView = 1,
    pagination = false,
    className = "",
    speed = 300,
    autoplay = defaultAutoplayOption,
    grabCursor = false,
    freeMode = false,
    clickSliderHandler,
}) => {
    const paginationData = useMemo(() => (pagination ? { clickable: true } : pagination), [pagination]);

    const clickHandler = useCallback(
        (index) => {
            clickSliderHandler && clickSliderHandler(index);
        },
        [clickSliderHandler],
    );

    return (
        <SliderWrapper className={className}>
            <Swiper
                freeMode={freeMode}
                grabCursor={grabCursor}
                slidesPerView={slidesPerView}
                centeredSlides={true}
                pagination={paginationData}
                loop={true}
                autoplay={autoplay}
                speed={speed}
                modules={[Pagination, Autoplay]}
            >
                {items.map((item, idx) => (
                    <SwiperSlide key={idx} onClick={() => clickHandler(idx)}>
                        {item}
                    </SwiperSlide>
                ))}
            </Swiper>
        </SliderWrapper>
    );
};

Slider.propTypes = {
    items: PropTypes.array.isRequired,
    slidesPerView: PropTypes.number,
    speed: PropTypes.number,
    pagination: PropTypes.bool,
    className: PropTypes.string,
    freeMode: PropTypes.bool,
    grabCursor: PropTypes.bool,
    autoplay: PropTypes.object,
    clickSliderHandler: PropTypes.func,
};

export default Slider;
