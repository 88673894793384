import { RED_COLOR } from "@assets/styles/tokens/colors";
import { secondaryFontFamily } from "@assets/styles/tokens/typography";
import { Text } from "@atoms/text";

import styled from "styled-components";

export const QrPopupWrapper = styled.div``;

export const QrPopupInnerWrapper = styled.div`
    margin-top: 12px;
`;

export const QrPopupTitle = styled(Text)`
    text-align: center;

    line-height: 28px;

    span {
        position: relative;
        z-index: 1;
        width: max-content;

        &:after {
            content: "";
            position: absolute;
            bottom: 5%;
            right: 50%;
            transform: translateX(50%);
            z-index: -1;

            width: 110%;
            height: 8px;

            background-color: ${RED_COLOR};
        }
    }
`;

export const QrPopupText = styled(Text)`
    margin-top: 12px;
    text-align: center;
    ${secondaryFontFamily}
`;

export const QrPopupCode = styled.div`
    padding: 0 32px;
    margin-top: 32px;
    svg {
        width: 100%;
        height: max-content;
    }
`;
