import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { NameDrinkStyled, NameDrinkWrapper } from "@atoms/name-drink/theme";
import { Text } from "@atoms/text";
import { DrinkTooltip } from "@molecules/drink-tooltip";
import { convertTypeSizeDrink } from "@molecules/menu-list-other-drink/helpers";

import React from "react";

import PropTypes from "prop-types";

const NameDrink = ({ drink, positionTooltip, offsetYTooltip }) => {
    return (
        <NameDrinkWrapper>
            {drink.tooltip ? (
                <DrinkTooltip text={drink.tooltip} offsetYTooltip={offsetYTooltip} positionTooltip={positionTooltip}>
                    <NameDrinkStyled isTooltip={true}>
                        <Text size="text48" weight="bold" className="name-drink" color={WHITE_COLOR}>
                            {drink.title}
                            {drink.size && (
                                <Text size="text24" className="name-drink name-drink_size">{`${
                                    drink.size
                                }${convertTypeSizeDrink(drink.sizeType)}`}</Text>
                            )}
                        </Text>
                    </NameDrinkStyled>
                </DrinkTooltip>
            ) : (
                <NameDrinkStyled isTooltip={false}>
                    <Text size="text48" weight="bold" className="name-drink">
                        {drink.title}
                        {drink.size && (
                            <Text size="text24" className="name-drink name-drink_size">{`${
                                drink.size
                            }${convertTypeSizeDrink(drink.sizeType)}`}</Text>
                        )}
                    </Text>
                </NameDrinkStyled>
            )}
        </NameDrinkWrapper>
    );
};

NameDrink.propTypes = {
    drink: PropTypes.object.isRequired,
    positionTooltip: PropTypes.string,
    offsetYTooltip: PropTypes.number,
};

export default NameDrink;
