import {
    text12,
    text14,
    text16,
    text20,
    text24,
    text32,
    text40,
    text48,
    text72,
} from "@assets/styles/tokens/typography";

import styled, { SimpleInterpolation, css } from "styled-components";

const getSize = (size) => {
    switch (size) {
        case "text12": {
            return css`
                ${text12}
            `;
        }
        case "text14": {
            return css`
                ${text14}
            `;
        }
        case "text16": {
            return css`
                ${text16}
            `;
        }
        case "text20": {
            return css`
                ${text20}
            `;
        }
        case "text24": {
            return css`
                ${text24}
            `;
        }
        case "text32": {
            return css`
                ${text32}
            `;
        }
        case "text40": {
            return css`
                ${text40}
            `;
        }
        case "text48": {
            return css`
                ${text48}
            `;
        }
        case "text72": {
            return css`
                ${text72}
            `;
        }
        default: {
            return;
        }
    }
};

export const TextWrapper = styled.div`
    color: ${({ color }) => color};
    ${({ size }) => getSize(size)}
    &&& {
        ${({ weight }) => weight}
    }
`;
