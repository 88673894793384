import React from "react";

const initState = {
    typeBreakpoint: "desktop",
    setTypeBreakpoint: () => false,
};

const TypeBreakpointContext = React.createContext(initState);

export default TypeBreakpointContext;
