import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { TabButton, TabWrapper } from "@atoms/tab/theme";
import { Text } from "@atoms/text";

import React, { useMemo } from "react";

import PropTypes from "prop-types";

const Tab = ({ title, name, active, clickHandler }) => {
    const tabClasses = useMemo(() => {
        return active ? "active" : "";
    }, [active]);
    return (
        <TabWrapper className={tabClasses}>
            <TabButton type="button" onClick={() => clickHandler(name)}>
                <Text weight="bold" size="text48" color={WHITE_COLOR}>
                    {title}
                </Text>
            </TabButton>
        </TabWrapper>
    );
};

Tab.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    clickHandler: PropTypes.func.isRequired,
};

export default Tab;
