import styled from "styled-components";

export const MinimalListDrinkMinimalWrapper = styled.div`
    margin-top: 64px;
`;

export const MinimalListDrinkMinimalItem = styled.div`
    margin-top: 40px;
    &:first-child {
        margin-top: 0;
    }
`;

export const MinimalListDrinkTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;

    margin-right: 12px;
    .name-drink {
        white-space: nowrap;
    }
`;
export const MinimalListDrinkTitleWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const MinimalListDrinkMinimalInnerWrapper = styled.div`
    margin-top: 8px;
`;

export const MinimalListDrinkMinimalRow = styled.div`
    display: flex;
    justify-content: space-between;
    &:first-child {
        align-items: flex-end;

        .menu-list-drinks-minimal-size {
            display: flex;
            align-items: flex-end;

            line-height: 0.5;

            margin-left: 5px;
        }
    }
    &:last-child {
        margin-top: 12px;
    }
`;

export const MinimalListDrinkMinimalColumn = styled.div`
    min-width: 33%;

    display: flex;
    align-content: flex-end;
`;

export const MinimalListDrinkMinimalColumnUno = styled(MinimalListDrinkMinimalColumn)`
    img {
        max-width: 15px;
    }
`;
export const MinimalListDrinkMinimalColumnMedium = styled(MinimalListDrinkMinimalColumn)`
    img {
        max-width: 19px;
    }
`;
export const MinimalListDrinkMinimalColumnGrande = styled(MinimalListDrinkMinimalColumn)`
    img {
        max-width: 25px;
    }
`;
