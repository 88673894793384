import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";

import React, { useContext, useEffect, useState } from "react";
import { Popup as TooltipComponent } from "reactjs-popup";

import PropTypes from "prop-types";

const Tooltip = ({ trigger, children, position = "right center", offsetY = 0 }) => {
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    const [onEvents, setOnEvents] = useState(["hover"]);

    useEffect(() => {
        if (typeBreakpoint === "desktop") setOnEvents(["hover", "click"]);
        else setOnEvents(["hover"]);
    }, [typeBreakpoint]);

    return (
        <TooltipComponent
            className="main-tooltip"
            offsetY={offsetY}
            trigger={trigger}
            position={position}
            on={onEvents}
            arrow={false}
            closeOnDocumentClick
        >
            {children}
        </TooltipComponent>
    );
};

Tooltip.propTypes = {
    trigger: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    position: PropTypes.string,
    offsetY: PropTypes.number,
};

export default Tooltip;
