export const SIZES = {
    xxxxxs: "2px",
    xxxxs: "4px",
    xxxs: "8px",
    xxxss: "11px",
    xxs: "14px",
    xs: "16px",
    xss: "18px",
    s: "20px",
    sm: "24px",
    smm: "27px",
    m: "32px",
    ms: "36px",
    l: "40px",
    ls: "44px",
    xl: "48px",
    xxl: "56px",
    xxlss: "59px",
    xxls: "60px",
    xxxl: "64px",
    xxxlsss: "72px",
    xxxlss: "76px",
    xxxls: "80px",
    none: "0px",
    auto: "auto",
};
