import "@assets/fonts/fonts.css";
import { indentClassNames } from "@assets/styles/indent-classnames";
import "@assets/styles/popup/qr-popup.scss";
import "@assets/styles/popup/tooltip-popup.scss";
import { BLACK_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import "@assets/styles/tokens/normalize";
import { normalize } from "@assets/styles/tokens/normalize";
import { fontFamily, fontSize20, secondaryFontFamily } from "@assets/styles/tokens/typography";
import { mainContentWrapper } from "@assets/styles/utils";

import { createGlobalStyle, css } from "styled-components";

export const containerApp = css`
    .container {
        ${mainContentWrapper};
    }
`;

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  #app {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 320px;
          min-height: 100%;
  };
  
  body {
    color: ${WHITE_COLOR};
    background-color: ${BLACK_COLOR};
    background-size: cover;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    ${fontFamily}
    ${fontSize20}
    ${containerApp}
    ${indentClassNames}
  }

  html {
    scroll-behavior: smooth;
  };
  
  button {
    -webkit-tap-highlight-color:  transparent;
  }

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
  .address-sidebar-enter,
  .current-sidebar-enter {
    opacity: 0;
  }

  .address-sidebar-enter-active,
  .current-sidebar-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  .address-sidebar-exit,
  .current-sidebar-exit {
    opacity: 1;
  }

  .address-sidebar-exit-active,
  .current-sidebar-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export default GlobalStyle;
