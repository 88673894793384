import { WHITE_COLOR } from "@assets/styles/tokens/colors";

import styled from "styled-components";

export const ButtonMoreWrapper = styled.div`
    margin-top: 64px;
    padding-top: 16px;

    border-top: 4px solid ${WHITE_COLOR};
`;

export const ButtonMoreWrapperComponent = styled.button`
    background: transparent;
    border: none;
`;
