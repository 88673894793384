import { BLACK_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import { AddressCard } from "@atoms/address-card";
import { Text } from "@atoms/text";
import MapContext from "@context/map/mapContext";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import {
    AddressSidebarCurrentWrapper,
    AddressSidebarInnerWrapper,
    AddressSidebarList,
    AddressSidebarListWrapper,
    AddressSidebarToggleBtn,
    AddressSidebarToggleBtnWrapper,
    AddressSidebarWrapper,
} from "@molecules/address-sidebar/theme";
import { CurrentAddressSidebar } from "@molecules/current-address-sidebar";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CSSTransition } from "react-transition-group";

import PropTypes from "prop-types";

import AddressSearch from "../address-search/address-search";

const tabs = [
    {
        title: "Карта",
        name: "map",
    },
    {
        title: "Список",
        name: "list",
    },
];

const AddressSidebar = ({ storesList, currentAddress = {}, isShowCurrentAddress, setIsShowCurrentAddress }) => {
    const [isShowList, setIsShowList] = useState(true);
    const [localIsShowCurrentAddress, setLocalIsShowCurrentAddress] = useState(isShowCurrentAddress);
    const [addressValue, setAddressValue] = useState("");
    const [localCurrentAddress, setLocalCurrentAddress] = useState(currentAddress);
    const [currentTab, setCurrentTab] = useState("map");
    const [isFocusedAddress, setIsFocusedAddress] = useState(false);
    const { typeBreakpoint } = useContext(TypeBreakpointContext);

    const addressListRef = React.useRef(null);
    const currentAddressRef = React.useRef(null);

    const [mapInstance] = React.useContext(MapContext);

    const timeout = typeBreakpoint === "mobile" ? 0 : 300;

    useEffect(() => {
        setLocalCurrentAddress(currentAddress);
    }, [currentAddress]);

    useEffect(() => {
        setLocalIsShowCurrentAddress(isShowCurrentAddress);
    }, [isShowCurrentAddress]);

    const handleAddressSearch = useCallback((address) => {
        setAddressValue(address);
    }, []);

    const filteredStore = useMemo(() => {
        return addressValue && /[a-zа-яё]/i.test(addressValue.toLowerCase())
            ? storesList.filter((store) => store.address.toLowerCase().indexOf(addressValue.toLowerCase()) >= 0)
            : storesList;
    }, [addressValue, storesList]);

    const clickHandler = useCallback(
        (localIsShowCurrentAddress, storeDate) => {
            if (typeBreakpoint === "mobile") {
                setCurrentTab("map");
                mapInstance.setCenter([storeDate.lat, storeDate.lon]);
                mapInstance.setZoom(16);

                return;
            }

            setLocalIsShowCurrentAddress(localIsShowCurrentAddress);
            mapInstance.setCenter([storeDate.lat, storeDate.lon]);
            mapInstance.setZoom(16);
        },
        [mapInstance, typeBreakpoint],
    );

    const clickHandlerBack = useCallback(
        (isShowList) => {
            setIsShowList(isShowList);
            setIsShowCurrentAddress(false);
            mapInstance.setCenter([59.923013148014775, 30.29196468139644]);
            mapInstance.setZoom(12);
        },
        [mapInstance, setIsShowCurrentAddress],
    );

    const isShowListAddress = useMemo(
        () => typeBreakpoint !== "mobile" || (typeBreakpoint === "mobile" && currentTab === "list"),
        [typeBreakpoint, currentTab],
    );

    const addressSidebarWrapperClasses = useMemo(
        () => (currentTab === "list" || localIsShowCurrentAddress ? "active-list" : ""),
        [currentTab, localIsShowCurrentAddress],
    );

    useEffect(() => {
        if (typeBreakpoint === "mobile" && isFocusedAddress) setCurrentTab("list");
    }, [isFocusedAddress, typeBreakpoint]);

    return (
        <AddressSidebarWrapper className={addressSidebarWrapperClasses}>
            <CSSTransition
                in={isShowList}
                timeout={300}
                nodeRef={addressListRef}
                classNames="address-sidebar"
                onEnter={() => setLocalIsShowCurrentAddress(false)}
                unmountOnExit
            >
                <AddressSidebarInnerWrapper ref={addressListRef}>
                    <AddressSearch
                        handleAddressSearch={handleAddressSearch}
                        addressValue={addressValue}
                        isFocusedAddress={isFocusedAddress}
                        setIsFocusedAddress={setIsFocusedAddress}
                    />
                    <AddressSidebarToggleBtnWrapper>
                        {tabs.map((tab) => (
                            <AddressSidebarToggleBtn
                                key={tab.name}
                                type="button"
                                onClick={() => setCurrentTab(tab.name)}
                                active={tab.name === currentTab}
                            >
                                <Text
                                    weight="medium"
                                    size="text14"
                                    color={tab.name === currentTab ? BLACK_COLOR : WHITE_COLOR}
                                >
                                    {tab.title}
                                </Text>
                            </AddressSidebarToggleBtn>
                        ))}
                    </AddressSidebarToggleBtnWrapper>
                    {isShowListAddress && (
                        <AddressSidebarList>
                            <AddressSidebarListWrapper>
                                {filteredStore.map((store, idx) => (
                                    <AddressCard
                                        key={idx}
                                        clickHandler={clickHandler}
                                        setLocalCurrentAddress={setLocalCurrentAddress}
                                        storeData={store}
                                    />
                                ))}
                            </AddressSidebarListWrapper>
                        </AddressSidebarList>
                    )}
                </AddressSidebarInnerWrapper>
            </CSSTransition>
            <CSSTransition
                in={localIsShowCurrentAddress}
                timeout={timeout}
                classNames="current-sidebar"
                nodeRef={currentAddressRef}
                onEnter={() => setIsShowList(false)}
                unmountOnExit
            >
                <AddressSidebarCurrentWrapper ref={currentAddressRef}>
                    <CurrentAddressSidebar clickHandler={clickHandlerBack} currentAddress={localCurrentAddress} />
                </AddressSidebarCurrentWrapper>
            </CSSTransition>
        </AddressSidebarWrapper>
    );
};

AddressSidebar.propTypes = {
    storesList: PropTypes.array.isRequired,
    currentAddress: PropTypes.object.isRequired,
    isShowCurrentAddress: PropTypes.bool.isRequired,
    setIsShowCurrentAddress: PropTypes.func.isRequired,
};

export default AddressSidebar;
