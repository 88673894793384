import styled from "styled-components";

export const AddressSearchWrapper = styled.div`
    display: flex;

    label {
        margin-left: 12px;
        flex-grow: 1;

        line-height: 0;
    }
`;
