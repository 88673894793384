import Search from "@assets/icons/search.svg";
import { Input } from "@atoms/input";
import { AddressSearchWrapper } from "@molecules/address-search/theme";

import React from "react";

import PropTypes from "prop-types";

const AddressSearch = ({ handleAddressSearch, addressValue, isFocusedAddress, setIsFocusedAddress }) => {
    return (
        <AddressSearchWrapper>
            <img src={Search} alt="" />
            <Input
                placeholder="Введите адрес или станцию метро"
                onChange={handleAddressSearch}
                value={addressValue}
                onFocus={setIsFocusedAddress}
                isFocus={isFocusedAddress}
            />
        </AddressSearchWrapper>
    );
};

AddressSearch.propTypes = {
    handleAddressSearch: PropTypes.func.isRequired,
    addressValue: PropTypes.string.isRequired,
    isFocusedAddress: PropTypes.bool.isRequired,
    setIsFocusedAddress: PropTypes.func.isRequired,
};

export default AddressSearch;
