import { Text } from "@atoms/text";
import { Tooltip } from "@atoms/tooltip";
import { DrinkTooltipWrapper } from "@molecules/drink-tooltip/theme";

import React from "react";

import PropTypes from "prop-types";

const DrinkTooltip = ({ children, text, positionTooltip, offsetYTooltip }) => {
    return (
        <Tooltip trigger={children} position={positionTooltip} offsetY={offsetYTooltip}>
            <DrinkTooltipWrapper>
                <Text size="text14" color="#C7C7CC">
                    {text}
                </Text>
            </DrinkTooltipWrapper>
        </Tooltip>
    );
};

DrinkTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    positionTooltip: PropTypes.string,
    offsetYTooltip: PropTypes.number,
};

export default DrinkTooltip;
