import { Tab } from "@atoms/tab";
import { menuTabsArray } from "@molecules/menu-tabs/helpers";
import { MenuTabsWrapper } from "@molecules/menu-tabs/theme";

import React, { useCallback } from "react";

import PropTypes from "prop-types";

const MenuTabs = ({ setActiveTabHandler, activeTab }) => {
    const clickHandler = useCallback(
        (name) => {
            setActiveTabHandler(name);
        },
        [setActiveTabHandler],
    );

    return (
        <MenuTabsWrapper>
            {menuTabsArray.map((tab, idx) => (
                <Tab
                    key={idx}
                    title={tab.title}
                    name={tab.name}
                    clickHandler={clickHandler}
                    active={activeTab === tab.name}
                />
            ))}
        </MenuTabsWrapper>
    );
};

MenuTabs.propTypes = {
    setActiveTabHandler: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default MenuTabs;
