import { adaptive } from "@assets/styles/tokens/screen";

import { css } from "styled-components";

export const fontFamily = `
  font-family: "BebasNeue", sans-serif;
  font-weight: inherit;
  font-feature-settings: 'lnum';
  font-variant-numeric: lining-nums;
`;

export const secondaryFontFamily = `
  font-family: "Roboto", sans-serif;
  font-weight: inherit;
  font-feature-settings: 'lnum';
  font-variant-numeric: lining-nums;
`;

export const fontWeightNormal = `
  font-weight: normal;
`;

export const fontWeightExtraBold = `
  font-weight: 700;
`;

export const fontWeightBold = `
  font-weight: 700;
`;

export const fontWeightMedium = `
  font-weight: 500;
`;

export const lineHeightNormal = css`
    line-height: normal;
`;

export const letterSpacingNormal = css`
    letter-spacing: normal;
`;
export const letterSpacing1 = css`
    letter-spacing: normal;
`;

export const fontSize14 = css`
    font-size: 14px;
`;

export const fontSize12 = css`
    font-size: 12px;
`;

export const fontSize16 = css`
    font-size: 16px;
`;

export const fontSize20 = css`
    font-size: 20px;
`;

export const fontSize24 = css`
    font-size: 24px;
`;

export const fontSize32 = css`
    font-size: 32px;
`;

export const fontSize40 = css`
    font-size: 40px;
`;

export const fontSize48 = css`
    font-size: 48px;
`;

export const fontSize72 = css`
    font-size: 72px;
`;

export const text12 = css`
    font-weight: normal;
    ${fontSize12};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize12};
    }
`;

export const text14 = css`
    font-weight: normal;
    ${fontSize14};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize14};
    }
`;

export const text16 = css`
    font-weight: normal;
    ${fontSize16};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize16};
    }
`;

export const text20 = css`
    font-weight: normal;
    ${fontSize20};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize20};
    }
`;

export const text24 = css`
    font-weight: normal;
    ${fontSize24};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize24};
    }
`;

export const text32 = css`
    font-weight: normal;
    ${fontSize32};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize32};
    }
`;

export const text40 = css`
    font-weight: normal;
    ${fontSize40};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize40};
    }
`;

export const text48 = css`
    font-weight: normal;
    ${fontSize40};
    ${letterSpacingNormal};
    ${adaptive.minWidth.tablet} {
        ${fontSize48};
    }
`;

export const text72 = css`
    font-weight: normal;
    ${fontSize72};
    ${letterSpacingNormal};
    ${adaptive.minWidth.desktop} {
        ${fontSize72};
    }
`;

export const title32 = css`
    font-weight: normal;
    ${fontSize24};
    ${letterSpacingNormal};
    ${adaptive.minWidth.tablet} {
        ${fontSize32};
    }
`;

export const title48 = css`
    font-weight: normal;
    ${fontSize40};
    ${letterSpacingNormal};
    ${adaptive.minWidth.tablet} {
        ${fontSize48};
    }
`;

export const title72 = css`
    font-weight: normal;
    ${fontSize40};
    ${letterSpacingNormal};
    ${adaptive.minWidth.tablet} {
        line-height: 83px;
        ${fontSize72};
    }
`;
