const __DOMAIN__ = "https://api.coffeebon.ru/appApi/v2.0/";

class ApiCall {
    constructor(domain) {
        this.domain = domain;
    }

    async perform(url, method, data) {
        try {
            const response = await fetch(`${this.domain}/${url}`, {
                method,
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                },
            });

            return {
                data: await response.json(),
                error: null,
            };
        } catch (error) {
            return {
                data: {},
                error: error,
            };
        }
    }

    async get(path) {
        return await this.perform(path);
    }

    async post(path, data) {
        return await this.perform(path, "POST", data && data);
    }
}

export const apiCall = new ApiCall(__DOMAIN__);
