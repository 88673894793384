import ButtonMore from "@atoms/button-more/button-more";
import { menuListDesserts, menuListFood } from "@molecules/menu-list-food/helpers";
import MenuListFood from "@molecules/menu-list-food/menu-list-food";
import { MenuListFoodContainerWrapper } from "@organisms/menu-list-food-container/theme";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

const MenuListFoodContainer = ({ activeTab }) => {
    const [isShowAllList, setIsShowAllList] = useState(false);
    const [listFood, setListFood] = useState([]);

    const checkFoodList = useCallback((drink) => {
        switch (drink) {
            case "food":
                return menuListFood;
            case "desserts":
                return menuListDesserts;
            default:
                return [];
        }
    }, []);

    const clickMoreHandler = useCallback(() => {
        if (isShowAllList) {
            setListFood(checkFoodList(activeTab));
            setIsShowAllList(false);
        } else {
            setListFood(checkFoodList(activeTab).slice(0, 8));
            setIsShowAllList(true);
        }
    }, [activeTab, checkFoodList, isShowAllList]);

    const isShowMoreList = useMemo(() => checkFoodList(activeTab).length > 8, [activeTab, checkFoodList]);

    useEffect(() => {
        if (isShowMoreList) {
            setListFood(checkFoodList(activeTab).slice(0, 8));
            setIsShowAllList(true);
            return;
        }

        setListFood(checkFoodList(activeTab));
        setIsShowAllList(false);
    }, [activeTab, checkFoodList, isShowMoreList]);
    return (
        <MenuListFoodContainerWrapper>
            <MenuListFood list={listFood} />
            {isShowMoreList && <ButtonMore clickHandler={clickMoreHandler} isActiveMoreState={isShowAllList} />}
        </MenuListFoodContainerWrapper>
    );
};

MenuListFoodContainer.propTypes = {
    activeTab: PropTypes.string.isRequired,
};

export default MenuListFoodContainer;
