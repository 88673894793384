export const breakpoints = {
    extraMobileSmall: 321,
    mobile: 360,
    tablet: 768,
    desktop: 1128,
};

export const adaptive = {
    minWidth: {
        extraMobileSmall: `@media only screen and (min-width: ${breakpoints.extraMobileSmall}px)`,
        mobile: `@media only screen and (min-width: ${breakpoints.mobile}px)`,
        tablet: `@media only screen and (min-width: ${breakpoints.tablet}px)`,
        desktop: `@media only screen and (min-width: ${breakpoints.desktop}px)`,
    },
    maxWidth: {
        extraMobileSmall: `@media only screen and (max-width: ${breakpoints.extraMobileSmall - 1}px)`,
        mobile: `@media only screen and (max-width: ${breakpoints.mobile - 1}px)`,
        tablet: `@media only screen and (max-width: ${breakpoints.tablet - 1}px)`,
        desktop: `@media only screen and (max-width: ${breakpoints.desktop - 1}px)`,
    },
};
