import Swiper1 from "@assets/image/slider/slider1.png";
import Swiper2 from "@assets/image/slider/slider2.png";
import Swiper3 from "@assets/image/slider/slider3.png";
import Swiper4 from "@assets/image/slider/slider4.png";
import Swiper5 from "@assets/image/slider/slider5.png";
import Swiper6 from "@assets/image/slider/slider6.png";
import Swiper7 from "@assets/image/slider/slider7.png";
import Swiper8 from "@assets/image/slider/slider8.png";
import Swiper9 from "@assets/image/slider/slider9.png";
import Swiper10 from "@assets/image/slider/slider10.png";
import Swiper11 from "@assets/image/slider/slider11.png";
import Swiper12 from "@assets/image/slider/slider12.png";
import Swiper13 from "@assets/image/slider/slider13.png";
import Swiper14 from "@assets/image/slider/slider14.png";
import Swiper15 from "@assets/image/slider/slider15.png";
import Swiper16 from "@assets/image/slider/slider16.png";
import Swiper17 from "@assets/image/slider/slider17.png";
import Swiper18 from "@assets/image/slider/slider18.png";

export const photos = [
    Swiper1,
    Swiper2,
    Swiper3,
    Swiper4,
    Swiper5,
    Swiper6,
    Swiper7,
    Swiper8,
    Swiper9,
    Swiper10,
    Swiper11,
    Swiper12,
    Swiper13,
    Swiper14,
    Swiper15,
    Swiper16,
    Swiper17,
    Swiper18,
];
