import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { ButtonMoreWrapper, ButtonMoreWrapperComponent } from "@atoms/button-more/theme";
import { Text } from "@atoms/text";

import React, { useMemo } from "react";

import PropTypes from "prop-types";

const ButtonMore = ({ clickHandler, isActiveMoreState, buttonText }) => {
    const checkButtonText = useMemo(() => {
        if (buttonText) return buttonText;

        return isActiveMoreState ? "Показать все" : "Свернуть";
    }, [buttonText, isActiveMoreState]);
    return (
        <ButtonMoreWrapper>
            <ButtonMoreWrapperComponent onClick={clickHandler}>
                <Text size="text24" color={WHITE_COLOR}>
                    {checkButtonText}
                </Text>
            </ButtonMoreWrapperComponent>
        </ButtonMoreWrapper>
    );
};

ButtonMore.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    isActiveMoreState: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
};

export default ButtonMore;
