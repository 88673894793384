import EmptyFoodImage from "@assets/image/food/empty_food.svg";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize12, fontSize14, fontSize32, secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled, { css } from "styled-components";

const getImageFood = (src) => {
    if (src) {
        return css`
            background-image: url(${({ src }) => src});
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            background-position: center center;
        `;
    }
    return css`
        background-color: rgba(255, 255, 255, 0.15);
        background-image: url(${EmptyFoodImage});
        background-repeat: no-repeat;
        background-position: center center;
    `;
};

export const FoodPopupWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${adaptive.minWidth.tablet} {
        flex-direction: row;
    }

    position: relative;

    button {
        position: absolute;

        right: 16px;
        top: 16px;
    }
`;

export const FoodPopupInnerWrapper = styled.div`
    &.content-wrapper {
        margin: 32px 24px 24px;

        flex-grow: 1;

        display: flex;
        flex-direction: column;
    }
`;

export const FoodPopupImage = styled.div`
    width: 100%;
    height: 328px;

    ${adaptive.minWidth.tablet} {
        width: 360px;
        height: 360px;
    }

    ${({ src }) => getImageFood(src)}
`;

export const FoodPopupTitle = styled.div`
    ${adaptive.minWidth.tablet} {
        width: 95%;
    }
    h5 {
        ${fontSize32};
    }
`;

export const FoodPopupDescription = styled.div`
    margin-top: 8px;
    p {
        line-height: 19.6px;
        ${secondaryFontFamily}
        ${fontSize12}
        ${adaptive.minWidth.tablet} {
            ${fontSize14}
        }
    }
`;

export const FoodPopupPrice = styled.div`
    flex-grow: 1;

    display: flex;
    align-items: flex-end;

    margin-top: 32px;
    p {
        &:first-child {
            line-height: 0.8;
            margin-right: 5px;
        }
    }
`;
