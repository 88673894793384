import React from "react";

import PropTypes from "prop-types";

const Section = ({ children, fluidComponent, className }) => {
    const classes = className ? className : "";
    const container = <div className={`container ${classes}`}>{children}</div>;
    return (
        <>
            {fluidComponent ? (
                <fluidComponent.component {...fluidComponent.props}>{container}</fluidComponent.component>
            ) : (
                container
            )}
        </>
    );
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    fluidComponent: PropTypes.object,
    className: PropTypes.string,
};

export default Section;
