import React, { useEffect } from "react";

const CheckTypeMobile = () => {
    useEffect(() => {
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/)) {
            location.href = "https://apps.apple.com/ru/app/coffee-bon/id6446148716";
        } else {
            location.href = "https://play.google.com/store/apps/details?id=info.opens.coffeebon&hl=ru&gl=US";
        }
    }, []);
    return <div />;
};

export default CheckTypeMobile;
