import NameDrink from "@atoms/name-drink/name-drink";
import { PriceDrink } from "@atoms/price-drink";
import { Text } from "@atoms/text";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";

import React, { useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
    MenuListOtherDrinkColumn,
    MenuListOtherDrinkInnerWrapper,
    MenuListOtherDrinkRow,
    MenuListOtherDrinkTitle,
    MenuListOtherDrinkWrapper,
} from "./theme";

const MenuListOtherDrink = ({ list }) => {
    const [positionTooltip, setPositionTooltip] = useState("right center");
    const { typeBreakpoint } = useContext(TypeBreakpointContext);

    useEffect(() => {
        if (typeBreakpoint !== "mobile") setPositionTooltip("right center");
        else setPositionTooltip("top left");
    }, [typeBreakpoint]);

    return (
        <MenuListOtherDrinkWrapper>
            <MenuListOtherDrinkTitle>
                <MenuListOtherDrinkColumn className="jc-c">
                    <Text weight="bold" size="text24">
                        цена за штуку
                    </Text>
                </MenuListOtherDrinkColumn>
            </MenuListOtherDrinkTitle>
            <MenuListOtherDrinkInnerWrapper>
                {list.map((drink, idx) => (
                    <MenuListOtherDrinkRow key={idx}>
                        <NameDrink drink={drink} positionTooltip={positionTooltip} offsetYTooltip={20} />

                        <MenuListOtherDrinkColumn className="d-flex ai-fe jc-c">
                            <PriceDrink price={drink.price} />
                        </MenuListOtherDrinkColumn>
                    </MenuListOtherDrinkRow>
                ))}
            </MenuListOtherDrinkInnerWrapper>
        </MenuListOtherDrinkWrapper>
    );
};

MenuListOtherDrink.propTypes = {
    list: PropTypes.array.isRequired,
};

export default MenuListOtherDrink;
