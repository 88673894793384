import { PopupWrapper } from "@atoms/popup/theme";

import React from "react";
import { Popup as ReactPopup } from "reactjs-popup";

import PropTypes from "prop-types";

const Popup = ({ trigger, children, className = "" }) => {
    return (
        <PopupWrapper>
            <ReactPopup className={`main-popup ${className}`} modal nested trigger={trigger} position="right center">
                {children}
            </ReactPopup>
        </PopupWrapper>
    );
};

Popup.propTypes = {
    trigger: PropTypes.node.isRequired,
    children: PropTypes.func,
    className: PropTypes.string,
};

export default Popup;
