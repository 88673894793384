import BalognesePasta from "@assets/image/food/balognese_pasta.jpg";
import Brownies from "@assets/image/food/brownies.jpg";
import Caesar from "@assets/image/food/caesar.jpg";
import CandyCoconut from "@assets/image/food/candy_coconut.jpg";
import CandyHoney from "@assets/image/food/candy_honey.jpg";
import CandyNuts from "@assets/image/food/candy_nuts.jpg";
import CarrotCake from "@assets/image/food/carrot_cake.jpg";
import CheeseCreamSoup from "@assets/image/food/cheese_cream_soup.jpg";
import CaramelCheesecake from "@assets/image/food/cheesecake_caramel.jpg";
//import ChocolateCheesecake from "@assets/image/food/cheesecake_chocolate.jpg";
import CrimsonCheesecake from "@assets/image/food/cheesecake_crimson.jpg";
import ChickenCurryRice from "@assets/image/food/chicken_curry_rice.jpg";
import ChickenInItalian from "@assets/image/food/chicken_in_Italian.jpg";
import ChickenSoup from "@assets/image/food/chicken_soup.jpg";
import ChickenWithTomatoes from "@assets/image/food/chicken_with_tomatoes.jpg";
import ChocolateCake from "@assets/image/food/chocolate_cake.jpg";
import ChocolateHeart from "@assets/image/food/chocolate_heart.jpg";
import ClassicCheesecake from "@assets/image/food/classic_cheesecake.jpg";
import CoconutCake from "@assets/image/food/coconut_cake.jpg";
import CranberrySandwich from "@assets/image/food/cranberry_sandwich.jpg";
import CreamyCake from "@assets/image/food/creamy_cake.jpg";
import CroissantCherry from "@assets/image/food/croissant_cherry.jpg";
import CroissantChocolate from "@assets/image/food/croissant_chocolate.jpg";
import CroissantClassic from "@assets/image/food/croissant_classic.jpg";
import Dumpling from "@assets/image/food/dumpling.jpg";
import HamThreeCheese from "@assets/image/food/ham_three_cheese.jpg";
import Hodgepodge from "@assets/image/food/hodgepodge.jpg";
import Kebab from "@assets/image/food/kebab.jpg";
import MushroomCreamSoup from "@assets/image/food/mushroom_cream_soup.jpg";
import Nuts from "@assets/image/food/nuts.jpg";
import PeqSoup from "@assets/image/food/pea_soup.jpg";
import Puree from "@assets/image/food/puree.jpg";
import SaladBoss from "@assets/image/food/salad_boss.jpg";
import SaladChickenLiver from "@assets/image/food/salad_chicken_liver.jpg";
import SaladWithTuna from "@assets/image/food/salad_with_tuna.jpg";
import CroissantSalted from "@assets/image/food/salted_croissant.jpg";
import SandwichBon from "@assets/image/food/sandwich_bon.jpg";
import StrawberryHeart from "@assets/image/food/strawberry_heart.jpg";
import SummerChill from "@assets/image/food/summer_chill.jpg";
import Syrniki from "@assets/image/food/syrniki.jpg";
import Tubule from "@assets/image/food/tubule.jpg";
import TunaSandwich from "@assets/image/food/tuna_sandwich.jpg";
import VeryMeaty from "@assets/image/food/very_meaty.jpg";

export const menuListDesserts = [
    {
        src: ClassicCheesecake,
        title: "Воздушный чизкейк на песочной основе",
        description: "Классический нежный сырно-творожный чизкейк на песочной основе.",
        price: 188,
    },
    /*     {
        src: CrimsonCheesecake,
        title: "Легкий чизкейк с натуральной малиной",
        description: "Популярный десерт с сырной основой и тонким коржом из песочного теста с добавлением малины.",
        price: 188,
    }, */
    /*   {
        src: Brownies,
        title: "Брауни  с бельгийским шоколадом",
        description:
            "Десерт американской кухни, главное в котором - шоколад. Брауни - это выпечка, что-то среднее между тортом, кексом и конфетой-тянучкой. Важно! В составе грецкий орех.",
        price: 198,
    }, */
    {
        src: Syrniki,
        title: "Нежные сырники как у бабушки",
        description:
            "Сырники - главное блюдо завтрака, чудо настраивающее на прекрасный день, любимое детское блюдо, угощение на десерт, лакомство на перекус среди дня… Хороши горячими, но вкусны и холодными.",
        price: 188,
    },
    {
        src: Dumpling,
        title: "Творожная запеканка с ароматным персиком ",
        description:
            "Нежная запеканка на твороге и сметане с тонким ванильным ароматом. Идеальна для завтрака и перекуса.",
        price: 161,
    },
    {
        src: CroissantSalted,
        title: "Круассан с соленой карамелью",
        description:
            "Если вы являетесь поклонником всего французского, попробовать наш круассан - ваша прямая обязанность!",
        price: 98,
    },
    {
        src: CroissantClassic,
        title: "Круассан классический",
        description: "Отличный выбор для завтрака, прям как во Франции!",
        price: 94,
    },
    {
        src: CroissantChocolate,
        title: "Круассан с шоколадом",
        description:
            'Круассан - в переводе с французского "полумесяц" - кондитерское изделие из слоеного теста, масла и шоколада.',
        price: 98,
    },
    {
        src: CroissantCherry,
        title: "Круассан с сыром",
        description: 'Воздушный "полумесяц" с сыром внутри и хрустящей корочкой.',
        price: 109,
    },
    {
        src: Tubule,
        title: "Трубочка",
        description: "Вкусная поджаристая вафля с густой сгущенкой",
        price: 77,
    },
    /*     {
        src: ChocolateHeart,
        title: "Шоколадное Сердечко",
        description: "Печенье со сгущенкой, в шоколадной глазури. Отлично подходит к кофе, чаю или какао.",
        price: 94,
    },
    {
        src: StrawberryHeart,
        title: "Клубничное сердечко",
        description: "Печенье со сгущенкой, в клубничной глазури. Отлично подходит к кофе, чаю или какао.",
        price: 94,
    }, */
    {
        src: Nuts,
        title: "Орешки со сгущенкой",
        description: "Орешки со сгущенкой, это потрясающее сочетание хрустящего песочного печенья и вареной сгущенки.",
        price: 77,
    },
    {
        src: null,
        title: "Маршмелоу",
        description: "Сладкая и нежная пастила, которая сможет покорить каждого",
        price: 131,
    },
    {
        src: CandyCoconut,
        title: "Конфета КОКО",
        description: "Кокосовая конфета с вишней в темной глазури",
        price: 59,
    },
    {
        src: CandyCoconut,
        title: "Конфета КОКО",
        description: "Кокосовая конфета с ананасом в белой глазури",
        price: 59,
    },
    {
        src: CandyCoconut,
        title: "Конфета КОКО",
        description: "Кокосовая конфета с клюквой и какао в темной глазури",
        price: 59,
    },
    {
        src: CandyHoney,
        title: "Конфета КОКО",
        description: "Конфета с начинкой 'медово-кукурузные хлопья' в темной глазури",
        price: 59,
    },
    {
        src: CandyNuts,
        title: "Конфета КОКО",
        description: "Конфета с медово-ореховой начинкой в темной глазури",
        price: 59,
    },

    /*   {
        src: null,
        title: "SOJ фруктово-ореховый",
        description: 'Фруктово-ореховый батончик со вкусом черники "миндаль-кешью-черника"',
        price: 119,
    },
    {
        src: null,
        title: "SOJ фруктово-ореховый",
        description: 'Фруктово-ореховый батончик со вкусом банана "арахис-банан"',
        price: 119,
    },
    {
        src: null,
        title: "SOJ MARSHMALLOW соленая карамель",
        description: "Батончик маршмелоу с соленой каркмелью в молочном шоколаде",
        price: 95,
    },
    {
        src: null,
        title: "SOJ MARSHMALLOW клубника",
        description: "Батончик маршмелоу с клубникой",
        price: 95,
    },
    {
        src: null,
        title: "SOJ ореховый батончик",
        description: "Ореховый батончик с ирисо-сливочным вкусом 'мед-миндаль-кешью'",
        price: 144,
    },
    {
        src: null,
        title: "SOJ ореховый батончик",
        description: "Ореховый батончик с медом 'орехи-мед-морская соль'",
        price: 144,
    },
    {
        src: null,
        title: "SOJ ореховый батончик",
        description: "Ореховый батончик со вкусом карамели 'миндаль-карамель'",
        price: 144,
    },
    {
        src: null,
        title: "SOJ PROTEIN соленая карамель",
        description: "Батончик с карамелью в бельгийском шоколаде",
        price: 119,
    },
    {
        src: null,
        title: "SOJ PROTEIN банан",
        description: "Батончик со вкусом банана в бельгийском шоколаде",
        price: 119,
    },
    {
        src: null,
        title: "ENERGY BAR ZERO кокос-клубника",
        description: "Энергетический батончик со вкусом клубники с кокосом",
        price: 148,
    },
    {
        src: null,
        title: "ENERGY BAR ZERO кокос-капучино",
        description: "Энергетический батончик со вкусом капучино с какосом",
        price: 148,
    },
    {
        src: null,
        title: "ENERGY BAR ZERO соленая карамель",
        description: "Энергетический батончик со вкусом соленой карамели",
        price: 1485,
    },
    {
        src: null,
        title: "Батончик BE-KIND",
        description: "Миндально-кокосовый батончик с медом",
        price: 148,
    },
    {
        src: null,
        title: "Батончик BE-KIND",
        description: "Ореховый батончик со вкусом миндаля и меда, морской соли и карамели",
        price: 138,
    },
    {
        src: null,
        title: "Батончик BE-KIND",
        description: "Ореховый батончик с темным щоколадом и морской солью",
        price: 138,
    }, */
];

export const menuListFood = [
    {
        src: VeryMeaty,
        title: "Сэндвич сочный мясной с соусом барбекю",
        description:
            "Каждый любитель остренького оценит этот сэндвич. В нем идеально сочетаются ломтики бекона, свинина, пепперони, маринованные огурцы и лук, фриллис, халапеньо и острый соус барбекю. Важно! В составе перец халапеньо и острый соус барбекю.",
        price: 208,
    },
    /*   {
        src: CranberrySandwich,
        title: "Сэндвич легкий с белым мясом и клюквой",
        description:
            "Сочетание запеченного куриного филе, клюквенного соуса, сливочного сыра и свежих овощей с зеленью. Важно! В составе клюквенный соус.",
        price: 228,
    }, */
    {
        src: HamThreeCheese,
        title: "Сэндвич с европейскими сырами и ветчиной",
        description:
            "Вкусный и сытный сэндвич для любителей фастфуда и быстрых перекусов. Ветчина, сливочный сыр, моцарелла, сыр маасдам, свежие огурцы и томаты, фриллис.",
        price: 198,
    },
    {
        src: ChickenWithTomatoes,
        title: "Сэндвич с копченым цыпленком и вялеными томатами",
        description:
            'Сытный сэндвич с нежным куриным филе, сыром моцарелла, вялеными томатами и соусом "черный перец".',
        price: 198,
    },
    {
        src: ChickenInItalian,
        title: "Сэндвич итальянский с курой и горчичным соусом",
        description:
            "Превосходное сочетание запеченного куриного филе, свежих томатов и фриллиса, моцареллы и горчично-майонезного соуса.",
        price: 174,
    },
    {
        src: TunaSandwich,
        title: "Сэндвич с тихоокеанским тунцом и кукурузой",
        description:
            "Уникальный сэндвич с тунцом,моцареллой, кукурузой, свежими овощами и горчично-майонезным соусом. Важно! В составе соуса горчица.",
        price: 218,
    },
    {
        src: SandwichBon,
        title: "Сэндвич фирменный с пышным омлетом и цыпленком",
        description: "Новый весенний сэндвич - легкий, но невероятно сытный, с омлетом и нежным куриным филе.",
        price: 144,
    },
    /*     {
        src: Caesar,
        title: "Классический цезарь с соусом из анчоусов",
        description:
            "Знаменитое блюдо, яркий пример того, как из подручных средств приготовить настоящий шедевр. Нежнейщее куриное филе, буквально тает во рту, салат Романо, сыр пармезан, томаты черри, хрустящие сухарики и майонезная заправка с анчоусами. Важно! В составе соус на основе анчоусов.",
        price: 278,
    }, */
    /*     {
        src: ChickenSoup,
        title: "Легкий бульон с цыпленком и овощами",
        description:
            "Ароматный куриный бульон обогащенный витаминами. Куриное филе, морковь, репчатый лук, лапша яичная, яйцо куриное, специи.",
        price: 188,
    }, */
    /*  {
        src: CheeseCreamSoup,
        title: "Нежный сырный крем-суп",
        description: "Нежное первое блюдо с плавленым сыром, картофелем, морковью, моцареллой, сыром пармезан.",
        price: 198,
    }, */
    /* {
        src: MushroomCreamSoup,
        title: "Грибной крем-суп на натуральных сливках",
        description:
            "Грибной крем-суп из шампиньонов - это традиционное блюдо европейской кухни, его вкусовые качества смогут удовлетворить любого гурмана.",
        price: 182,
    },
    {
        src: Hodgepodge,
        title: "Солянка с копченым ассорти и специями",
        description:
            "Густая, пряная, остренькая солянка с XVI века стала одним из любимейших супов в России. Основу супа составляют мясные изделия, маринованные огурцы и маслины, томатная паста, лимон, и конечно же, сметана.",
        price: 198,
    },
    {
        src: PeqSoup,
        title: "Наваристый гороховый суп с копченностями",
        description:
            "Ароматный гороховый суп с копченостями. Наваристое и сытное первое блюдо, отличный выбор на обед.  ",
        price: 198,
    },
    {
        src: ChickenCurryRice,
        title: "Нежное филе цыпленка карри с рисом",
        description:
            "Это простое и вкусное блюдо, которое готовиться из курицы, риса, пряностей и сливок. Блюдо имеет приятный необычный цвет, характерный и насыщенный аромат, но не слишком острый вкус.",
        price: 216,
    }, */
    {
        src: BalognesePasta,
        title: "Паста болоньезе с итальянским сыром",
        description:
            "Паста Болоньезе - известное и любимое во всем мире итальянское блюдо.Паста, основой которой является густой соус из томатов и рубленного мяса.",
        price: 216,
    },
    {
        src: Puree,
        title: "Мятый картофель с митболами в томатном соусе",
        description: "Сытное второе блюдо, отлично решение для обеда.",
        price: 216,
    },
];
