import MainLogo from "@assets/image/label.svg";
import { Section } from "@atoms/section";
import { Text } from "@atoms/text";
import { NavigationList } from "@molecules/navigation-list";
import { SocialLinksList } from "@molecules/social-links-list";
import { FooterBlock, FooterInnerWrapper, FooterSection, FooterWrapper } from "@organisms/footer/theme";
import { HomeSecondaryLogo } from "@pages/home/theme";

import React from "react";

import MarketPlace from "../../atoms/market-place/market-place";

const Footer = () => {
    return (
        <FooterWrapper>
            <Section>
                <FooterInnerWrapper>
                    <FooterBlock>
                        <FooterSection className="home-footer-section-one">
                            <HomeSecondaryLogo className="home-footer-img footer-left-section">
                                <img src={MainLogo} alt="" />
                            </HomeSecondaryLogo>
                            <div className="footer-right-section">
                                <Text size="text24" weight="normal" className="footer-marketplace-text">
                                    Установите мобильное приложение{" "}
                                    <Text size="text24" tag="span" weight="bold">
                                        Coffee Bon
                                    </Text>
                                </Text>
                                <MarketPlace className="footer-marketplace" />
                            </div>
                        </FooterSection>
                    </FooterBlock>
                    <FooterBlock>
                        <FooterSection className="footer-section-navigation">
                            <NavigationList isActiveClass={false} />
                            <SocialLinksList />
                        </FooterSection>
                    </FooterBlock>
                    <FooterBlock className="footer-copyright">
                        <FooterSection className="footer-section-copyright">
                            <Text size="text14" className="footer-copyright-text" color="#8E8E93">
                                Сеть ЭспрессоБаров Кофе Бон © 2022
                            </Text>
                            <div className="footer-info">
                                <Text size="text14" className="footer-copyright-text" color="#8E8E93">
                                    Ваше предложение по сотрудничеству <br /> можно прислать нам на почту
                                </Text>
                                <Text size="text14" className="footer-copyright-text" color="#8E8E93">
                                    marketing@coffeebon.ru
                                </Text>
                                <Text size="text14" className="footer-copyright-text" color="#8E8E93">
                                    тел. 8 (812) 309-95-10
                                </Text>
                            </div>
                        </FooterSection>
                    </FooterBlock>
                </FooterInnerWrapper>
            </Section>
        </FooterWrapper>
    );
};

export default Footer;
