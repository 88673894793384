import Back from "@assets/icons/back.svg";
import MetroIcon from "@assets/icons/metro.svg";
import { RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { Text } from "@atoms/text";
import {
    CurrentAddressSidebarBackBtn,
    CurrentAddressSidebarBackWrapper,
    CurrentAddressSidebarBreak,
    CurrentAddressSidebarContact,
    CurrentAddressSidebarContactPhone,
    CurrentAddressSidebarInnerWrapper,
    CurrentAddressSidebarStation,
    CurrentAddressSidebarStreet,
    CurrentAddressSidebarTime,
    CurrentAddressSidebarTimeText,
    CurrentAddressSidebarTitle,
    CurrentAddressSidebarWorked,
    CurrentAddressSidebarWrapper,
} from "@molecules/current-address-sidebar/theme";

import React, { useCallback, useMemo } from "react";
import { ReactSVG } from "react-svg";

import PropTypes from "prop-types";

const CurrentAddressSidebar = ({ clickHandler, currentAddress }) => {
    const workedHours = useMemo(() => {
        const currentDay = new Date().getDay();
        return currentDay === 7 ? currentAddress.weekendHours : currentAddress.workHours;
    }, [currentAddress.weekendHours, currentAddress.workHours]);

    const convertWorkHour = useCallback((time) => {
        const hours = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = `${parseInt((time % (1000 * 60 * 60)) / (1000 * 60))}`.padStart(2, "0");
        return `${hours}:${minutes}`;
    }, []);

    const convertTimeToMilliseconds = useCallback((hour, minutes) => {
        return hour * 3600000 + minutes * 60000;
    }, []);

    const isOpenStoreTime = useMemo(() => {
        const currentTime = convertTimeToMilliseconds(new Date().getHours(), new Date().getMinutes());
        return currentTime < workedHours.end && currentTime > workedHours.start;
    }, [convertTimeToMilliseconds, workedHours.end, workedHours.start]);

    const isOpenStoreDay = useMemo(() => {
        return (!currentAddress.weekendHours || currentAddress.weekendHours) && !currentAddress.daysWeek.length;
    }, [currentAddress]);

    const isOpenStore = useMemo(() => {
        return isOpenStoreTime && isOpenStoreDay;
    }, [isOpenStoreDay, isOpenStoreTime]);

    const openTextStore = useMemo(() => {
        return isOpenStore ? "Открыто" : "Закрыто";
    }, [isOpenStore]);

    const workDaysText = useMemo(() => {
        return currentAddress.weekendHours ? "ПН, ВТ, СР, ЧТ, ПТ, CБ" : "ПН-ВС";
    }, [currentAddress.weekendHours]);

    return (
        <CurrentAddressSidebarWrapper>
            <CurrentAddressSidebarBackWrapper>
                <CurrentAddressSidebarBackBtn onClick={() => clickHandler(true)}>
                    <img src={Back} alt="" />
                    <Text size="text14" color={WHITE_COLOR}>
                        Вернуться к списку
                    </Text>
                </CurrentAddressSidebarBackBtn>
            </CurrentAddressSidebarBackWrapper>
            <CurrentAddressSidebarInnerWrapper>
                {/*<CurrentAddressSidebarImageStore src={Store} />*/}
                <CurrentAddressSidebarStreet weight="medium">{currentAddress.address}</CurrentAddressSidebarStreet>
                <CurrentAddressSidebarStation color={currentAddress.color}>
                    <ReactSVG src={MetroIcon} />
                    <Text size="text14" className="address-card-station">
                        {currentAddress.station}
                    </Text>
                </CurrentAddressSidebarStation>
                <CurrentAddressSidebarWorked>
                    <Text size="text12" className="address-card-status" color={isOpenStore ? YELLOW_COLOR : RED_COLOR}>
                        {openTextStore}
                    </Text>
                    <Text size="text12" className="address-card-time" color="#8E8E93">
                        Работает до {convertWorkHour(workedHours.end)}
                    </Text>
                </CurrentAddressSidebarWorked>
                <CurrentAddressSidebarTime>
                    <CurrentAddressSidebarTitle size="text14" weight="medium">
                        График работы
                    </CurrentAddressSidebarTitle>
                    <div className="d-flex jc-sb">
                        <CurrentAddressSidebarTimeText
                            size="text14"
                            className={currentAddress.break ? "available-break" : ""}
                        >
                            {workDaysText}
                            {currentAddress.break && <span>*</span>}
                        </CurrentAddressSidebarTimeText>
                        <CurrentAddressSidebarTimeText size="text14">
                            C {convertWorkHour(currentAddress.workHours.start)} ДО{" "}
                            {convertWorkHour(currentAddress.workHours.end)}
                        </CurrentAddressSidebarTimeText>
                    </div>
                    {currentAddress.weekendHours && (
                        <div className="d-flex jc-sb">
                            <CurrentAddressSidebarTimeText size="text14">ВС</CurrentAddressSidebarTimeText>
                            <CurrentAddressSidebarTimeText size="text14">
                                C {convertWorkHour(currentAddress.weekendHours.start)} ДО{" "}
                                {convertWorkHour(currentAddress.weekendHours.end)}
                            </CurrentAddressSidebarTimeText>
                        </div>
                    )}
                    {currentAddress.break && (
                        <CurrentAddressSidebarBreak size="text14">
                            <span>*</span> перерыв {convertWorkHour(currentAddress.break.from)} -{" "}
                            {convertWorkHour(currentAddress.break.to)}
                        </CurrentAddressSidebarBreak>
                    )}
                </CurrentAddressSidebarTime>
                <CurrentAddressSidebarContact>
                    <CurrentAddressSidebarTitle size="text14" weight="medium">
                        Контакты
                    </CurrentAddressSidebarTitle>
                    <CurrentAddressSidebarContactPhone size="text16" color={YELLOW_COLOR} weight="medium">
                        +7(812) 309-95-10
                    </CurrentAddressSidebarContactPhone>
                </CurrentAddressSidebarContact>
            </CurrentAddressSidebarInnerWrapper>
        </CurrentAddressSidebarWrapper>
    );
};

CurrentAddressSidebar.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    currentAddress: PropTypes.object.isRequired,
};

export default CurrentAddressSidebar;
