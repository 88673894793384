import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const MapCoffeeWrapper = styled.div`
    padding-bottom: 240px;

    h5 {
        margin-bottom: 32px;
    }
`;

export const MapCoffeeStyled = styled.div`
    height: 600px;
    width: 100%;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    ${adaptive.minWidth.tablet} {
        flex-direction: row;
    }

    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
`;
