import { RED_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize16, fontSize24, fontSize32, fontSize48 } from "@assets/styles/tokens/typography";
import { transitionDefault } from "@assets/styles/utils";

import styled, { css } from "styled-components";

export const NameDrinkWrapper = styled.div``;

const isTooltipHandler = (tooltip) => {
    if (tooltip) {
        return css`
            cursor: pointer;
            background-color: transparent;
            border: none;

            ${transitionDefault}

            &:hover {
                p {
                    color: ${RED_COLOR};
                    ${transitionDefault}
                }
            }
        `;
    }
};

export const NameDrinkStyled = styled.div`
    .name-drink {
        margin-right: 12px;

        display: inline-block;

        line-height: 0.8;

        ${adaptive.minWidth.tablet} {
            ${fontSize32}
        }
        ${adaptive.minWidth.desktop} {
            ${fontSize48}
        }

        &_size {
            line-height: 1;
            margin-left: 5px;
            ${fontSize16}
            ${adaptive.minWidth.desktop} {
                ${fontSize24}
            }
        }
    }
    ${({ isTooltip }) => isTooltipHandler(isTooltip)}
`;
