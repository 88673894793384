import { RED_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { transitionDefault } from "@assets/styles/utils";

import styled from "styled-components";

export const NavigationItemWrapper = styled.li`
    display: flex;
    align-items: center;

    a {
        cursor: pointer;
        position: relative;
        z-index: 3;

        &.active {
            &:after {
                position: absolute;
                content: "";
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);

                z-index: -1;

                height: 8px;
                width: 110%;

                background-color: ${RED_COLOR};
            }
        }

        &:hover {
            span {
                color: ${RED_COLOR};
            }
        }

        &:active {
            span {
                color: ${YELLOW_COLOR};
            }
        }
    }

    span {
        text-transform: uppercase;
    }
`;
