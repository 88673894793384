import MetroIcon from "@assets/icons/metro.svg";
import { RED_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { AddressCardStation, AddressCardWrapper } from "@atoms/address-card/theme";
import { Text } from "@atoms/text";

import React, { useCallback, useMemo } from "react";
import { ReactSVG } from "react-svg";

import PropTypes from "prop-types";

const AddressCard = ({ clickHandler, setLocalCurrentAddress, storeData }) => {
    const clickHandlerItem = useCallback(() => {
        clickHandler(true, { lat: storeData.latitude, lon: storeData.longitude });
        setLocalCurrentAddress(storeData);
    }, [clickHandler, setLocalCurrentAddress, storeData]);

    const workedHours = useMemo(() => {
        const currentDay = new Date().getDay();
        return currentDay === 7 ? storeData.weekendHours : storeData.workHours;
    }, [storeData.weekendHours, storeData.workHours]);

    const convertWorkHour = useCallback((time) => {
        const hours = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = `${parseInt((time % (1000 * 60 * 60)) / (1000 * 60))}`.padStart(2, "0");
        return `${hours}:${minutes}`;
    }, []);

    const convertTimeToMilliseconds = useCallback((hour, minutes) => {
        return hour * 3600000 + minutes * 60000;
    }, []);

    const isOpenStoreTime = useMemo(() => {
        const currentTime = convertTimeToMilliseconds(new Date().getHours(), new Date().getMinutes());
        return currentTime < workedHours.end && currentTime > workedHours.start;
    }, [convertTimeToMilliseconds, workedHours.end, workedHours.start]);

    const isOpenStoreDay = useMemo(() => {
        return (!storeData.weekendHours || storeData.weekendHours) && !storeData.daysWeek.length;
    }, [storeData]);

    const isOpenStore = useMemo(() => {
        return isOpenStoreTime && isOpenStoreDay;
    }, [isOpenStoreDay, isOpenStoreTime]);

    const openTextStore = useMemo(() => {
        return isOpenStore ? "Открыто" : "Закрыто";
    }, [isOpenStore]);

    return (
        <AddressCardWrapper onClick={clickHandlerItem}>
            <Text size="text16" className="address-card-title">
                {storeData.address}
            </Text>
            <AddressCardStation color={storeData.color}>
                <ReactSVG src={MetroIcon} />
                <Text size="text14" className="address-card-station">
                    {storeData.station}
                </Text>
            </AddressCardStation>
            <div className="d-flex">
                <Text size="text12" className="address-card-status" color={isOpenStore ? YELLOW_COLOR : RED_COLOR}>
                    {openTextStore}
                </Text>
                <Text size="text12" className="address-card-time">
                    C {convertWorkHour(workedHours.start)} ДО {convertWorkHour(workedHours.end)}
                </Text>
            </div>
        </AddressCardWrapper>
    );
};

AddressCard.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    setLocalCurrentAddress: PropTypes.func.isRequired,
    storeData: PropTypes.object.isRequired,
};

export default AddressCard;
