import { adaptive } from "@assets/styles/tokens/screen";
import { text16, text32 } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const PriceDrinkWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    .price {
        line-height: 0.8;
        margin-right: 5px;
        ${adaptive.maxWidth.desktop} {
            line-height: 0.6;
            ${text32}
        }
    }

    .currency {
        ${adaptive.maxWidth.desktop} {
            ${text16}
        }
    }
`;
