import MinusControl from "@assets/icons/minus.svg";
import PlusControl from "@assets/icons/plus.svg";

import styled from "styled-components";

export const MapWrapper = styled.div`
    width: 100%;
    height: 100%;

    .ymaps-2-1-79-controls-pane {
        top: 45% !important;
    }

    .ymaps-2-1-79-zoom__button {
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0;
        border-width: 0;
        background: #404045;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
    }

    .ymaps-2-1-79-zoom {
        width: 40px;
        height: 30px !important;
    }

    .ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
        background-image: url(${PlusControl});
    }

    .ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon {
        background-image: url(${MinusControl});
    }

    .ymaps-2-1-79-float-button:active {
        background-color: #404045 !important;
    }

    .ymaps-2-1-79-zoom__icon {
        width: 40px;
        height: 40px;
        border: none;
    }
`;
