import { NavigationItem } from "@atoms/navigation-item";
import { navigationLinks } from "@molecules/navigation-list/helpers";
import { NavigationListWrapper } from "@molecules/navigation-list/theme";

import React from "react";

import PropTypes from "prop-types";

const NavigationList = ({ isActiveClass, className = "", weight = "normal", size = "text24", handleClickItem }) => {
    return (
        <NavigationListWrapper className={className}>
            {navigationLinks.map((link, idx) => (
                <NavigationItem
                    key={idx}
                    item={link}
                    isActiveClass={isActiveClass}
                    weight={weight}
                    size={size}
                    handleClick={handleClickItem}
                />
            ))}
        </NavigationListWrapper>
    );
};

NavigationList.propTypes = {
    isActiveClass: PropTypes.bool,
    className: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    handleClickItem: PropTypes.func,
};

export default NavigationList;
