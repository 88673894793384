export const convertTypeSizeDrink = (sizeType) => {
    switch (sizeType) {
        case "kilogram":
            return "кг";
        case "gram":
            return "г";
        case "liter":
            return "л";
        case "milliliter":
            return "мл";
        default:
            return;
    }
};
