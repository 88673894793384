import { adaptive } from "@assets/styles/tokens/screen";
import { SIZES } from "@assets/styles/tokens/sizes";

import { css } from "styled-components";

export const indentClassNames = css`
    .mrauto {
        margin-right: auto;
        &--tablet {
            ${adaptive.minWidth.desktop} {
                margin-right: auto;
            }
        }

        &--mobile {
            ${adaptive.minWidth.tablet} {
                margin-right: auto;
            }
        }
    }

    /* MARGIN LEFT AND RIGHT*/

    .mauto {
        margin-left: auto;
        margin-right: auto;
        &--tablet {
            ${adaptive.minWidth.desktop} {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &--mobile {
            ${adaptive.minWidth.tablet} {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .mr16 {
        margin-right: ${SIZES.xs};
    }
    .d-flex {
        display: flex;
    }
    .jc-sb {
        justify-content: space-between;
    }
    .jc-c {
        justify-content: center;
    }
    .ai-c {
        align-items: center;
    }
    .ai-s {
        align-items: start;
    }
    .ai-fe {
        align-items: flex-end;
    }
    .fd-c {
        flex-direction: column;
    }
    .fd-r {
        flex-direction: row;
    }
    .d-flex-tablet {
        ${adaptive.minWidth.tablet} {
            display: flex;
        }
    }
    .jc-sb-tablet {
        ${adaptive.minWidth.tablet} {
            justify-content: space-between;
        }
    }
    .ai-c-tablet {
        ${adaptive.minWidth.tablet} {
            align-items: center;
        }
    }
    .ai-s-tablet {
        ${adaptive.minWidth.tablet} {
            align-items: start;
        }
    }
    .fd-c-tablet {
        ${adaptive.minWidth.tablet} {
            flex-direction: column;
        }
    }
    .fd-r-tablet {
        ${adaptive.minWidth.tablet} {
            flex-direction: row;
        }
    }
    .d-flex-desktop {
        ${adaptive.minWidth.desktop} {
            display: flex;
        }
    }
    .jc-sb-desktop {
        ${adaptive.minWidth.desktop} {
            justify-content: space-between;
        }
    }
    .ai-c-desktop {
        ${adaptive.minWidth.desktop} {
            align-items: center;
        }
    }
    .ai-s-desktop {
        ${adaptive.minWidth.desktop} {
            align-items: start;
        }
    }
    .fd-c-desktop {
        ${adaptive.minWidth.desktop} {
            flex-direction: column;
        }
    }
    .fd-r-desktop {
        ${adaptive.minWidth.desktop} {
            flex-direction: row;
        }
    }
    /*ALIGN TEXT*/
    .ta-c {
        text-align: center;
    }
`;
