import styled from "styled-components";

export const MenuTabsWrapper = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-wrap: wrap;

    margin-bottom: 80px;

    gap: 20px;
`;
