import { HamburgerBtnWrapper } from "@atoms/hamburger-btn/theme";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

const HamburgerBtn = ({ setIsShowDrawer, isShowDrawer }) => {
    const [isActive, setIsActive] = useState(false);

    const clickHandler = useCallback(() => {
        setIsActive(!isActive);
        setIsShowDrawer(!isActive);
    }, [isActive, setIsShowDrawer]);

    const activeClass = useMemo(() => {
        return isActive ? "is-active" : "";
    }, [isActive]);

    useEffect(() => {
        setIsActive(isShowDrawer);
    }, [isShowDrawer]);

    return (
        <HamburgerBtnWrapper>
            <button className={`hamburger hamburger--squeeze ${activeClass}`} type="button" onClick={clickHandler}>
                <span className="hamburger-box">
                    <span className="hamburger-inner" />
                </span>
            </button>
        </HamburgerBtnWrapper>
    );
};

HamburgerBtn.propTypes = {
    setIsShowDrawer: PropTypes.func.isRequired,
    isShowDrawer: PropTypes.bool.isRequired,
};

export default HamburgerBtn;
