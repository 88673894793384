import { NavigationItemWrapper } from "@atoms/navigation-item/theme";
import { Text } from "@atoms/text";

import React, { useEffect, useRef } from "react";
import { Element, Events, Link } from "react-scroll";

import PropTypes from "prop-types";

const NavigationItem = ({ item, isActiveClass = false, weight = "normal", size = "text24", handleClick }) => {
    useEffect(() => {
        Events.scrollEvent.register("begin", function (to, element) {
            handleClick && handleClick();
        });

        return () => Events.scrollEvent.remove("begin");
    }, [handleClick]);
    return (
        <NavigationItemWrapper>
            <Link
                activeClass={isActiveClass ? "active" : "non-active"}
                to={item.id}
                spy={true}
                smooth={true}
                offset={-100}
                duration={100}
            >
                <Text size={size} tag="span" weight={weight}>
                    {item.title}
                </Text>
            </Link>
        </NavigationItemWrapper>
    );
};

NavigationItem.propTypes = {
    item: PropTypes.object.isRequired,
    isActiveClass: PropTypes.bool,
    weight: PropTypes.string,
    size: PropTypes.string,
    handleClick: PropTypes.func,
};

export default NavigationItem;
