import { RED_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const TabWrapper = styled.li`
    &.active {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            bottom: 5%;
            left: 50%;
            z-index: -1;

            transform: translateX(-50%);

            height: 16px;
            width: 110%;

            background-color: ${RED_COLOR};
        }
    }
`;

export const TabButton = styled.button`
    background: transparent;
    border: none;

    ${adaptive.minWidth.desktop} {
        &:hover {
            p {
                color: ${RED_COLOR};
            }
        }
    }

    &:active {
        p {
            color: ${YELLOW_COLOR};
        }
    }
`;
