import ArrowDown from "@assets/icons/arrow_down.svg";
import AdvantagesImage from "@assets/image/advantages.png";
import CoffeeCup from "@assets/image/coffee-cup.svg";
import MainLogo from "@assets/image/label.svg";
import { BLACK_COLOR } from "@assets/styles/tokens/colors";
import { Section } from "@atoms/section";
import { Text } from "@atoms/text";
import { Title } from "@atoms/title";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import { SliderPhotos } from "@molecules/slider-photos";
import { SliderStock } from "@molecules/slider-stock";
import { Menu } from "@organisms/menu";
import {
    HomeAdvantagesImg,
    HomeAdvantagesItem,
    HomeAdvantagesList,
    HomeAdvantagesWrapper,
    HomeFeedback,
    HomeFeedbackBtn,
    HomeOfferWrapper,
    HomeSecondaryLogo,
    HomeWrapper,
} from "@pages/home/theme";
import { Page } from "@templates/page";

import React, { useContext } from "react";
import { Link } from "react-scroll";

import MarketPlace from "../../components/atoms/market-place/market-place";
import MapCoffee from "../../components/organisms/map-coffee/map-coffee";

const Home = () => {
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    return (
        <Page>
            <HomeWrapper>
                <Section>
                    <HomeOfferWrapper>
                        <HomeSecondaryLogo className="home-offer-img">
                            <img src={MainLogo} alt="" />
                        </HomeSecondaryLogo>
                        <Title tag="h1" weight="normal">
                            Давай дружить! <br />
                            Установи мобильное приложение <Title tag="span">Coffee Bon</Title>
                        </Title>
                        <Text className="home-offer-text" color="#C7C7CC">
                            Все доступные бонусы и специальные предложения всегда под рукой
                        </Text>
                        <MarketPlace className="home-offer-marketplace" />
                        {typeBreakpoint !== "desktop" && (
                            <Link
                                to="advantages"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                                className="home-down-scroll"
                            >
                                <img src={ArrowDown} alt="" />
                            </Link>
                        )}
                    </HomeOfferWrapper>
                </Section>
                <Section>
                    <HomeAdvantagesWrapper id="advantages">
                        <HomeAdvantagesImg src={AdvantagesImage} />
                        <HomeAdvantagesList>
                            <HomeAdvantagesItem>
                                <Text size="text40">Накапливай баллы в личном профиле</Text>
                            </HomeAdvantagesItem>
                            <HomeAdvantagesItem>
                                <Text size="text40">Карта с любимыми кофейнями всегда под рукой</Text>
                            </HomeAdvantagesItem>
                            <HomeAdvantagesItem>
                                <Text size="text40">Следи за акциями и выгодными предложениями</Text>
                            </HomeAdvantagesItem>
                            <HomeAdvantagesItem>
                                <Text size="text40">Делись отзывом и получай подарки</Text>
                            </HomeAdvantagesItem>
                        </HomeAdvantagesList>
                    </HomeAdvantagesWrapper>
                </Section>
                <SliderPhotos />
                <Section>
                    <Menu />
                    <MapCoffee />
                    <SliderStock />
                    <HomeFeedback id="feedback">
                        <img src={CoffeeCup} alt="" />
                        <Title tag="h4" weight="normal">
                            Оставьте <Title tag="span">отзыв</Title> и получите подарок
                        </Title>
                        <HomeFeedbackBtn href="https://www.coffeebon.ru/mnenie/" target="_blank">
                            <Text color={BLACK_COLOR} weight="bold" size="text24">
                                Хочу подарок!
                            </Text>
                        </HomeFeedbackBtn>
                    </HomeFeedback>
                </Section>
            </HomeWrapper>
        </Page>
    );
};

export default Home;
