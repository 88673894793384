import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const InputWrapper = styled.input`
    background-color: transparent;

    width: 100%;

    color: ${WHITE_COLOR};

    font-size: 16px;
    ${secondaryFontFamily}
`;
