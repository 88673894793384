import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const SliderPhotosWrapper = styled.div`
    width: 100%;
    margin: 102px 0;
    ${adaptive.minWidth.tablet} {
        margin: 230px 0;
    }
    ${adaptive.minWidth.desktop} {
        margin: 200px 0;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }
    .swiper-slide {
        margin-right: 24px !important;

        cursor: pointer;
    }
`;

export const SliderPhotosItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 24px;
        width: 100%;
    }
`;
