import { Text } from "@atoms/text";
import { Title } from "@atoms/title";
import { FoodPopup } from "@molecules/food-popup";
import {
    MenuListCard,
    MenuListCardDescription,
    MenuListCardImage,
    MenuListCardPrice,
    MenuListCardTitle,
    MenuListCardWrapper,
    MenuListFoodInnerWrapper,
    MenuListFoodWrapper,
} from "@molecules/menu-list-food/theme";

import React from "react";

import PropTypes from "prop-types";

const MenuListFood = ({ list }) => {
    return (
        <MenuListFoodWrapper>
            <MenuListFoodInnerWrapper>
                {list.map((food, idx) => (
                    <FoodPopup
                        key={idx}
                        trigger={
                            <MenuListCard key={idx}>
                                <MenuListCardImage src={food.src} />
                                <MenuListCardWrapper>
                                    <MenuListCardTitle>
                                        <Title size="title32" weight="bold">
                                            {food.title}
                                        </Title>
                                    </MenuListCardTitle>
                                    <MenuListCardDescription>
                                        <Text size="text14">{food.description}</Text>
                                    </MenuListCardDescription>
                                    <MenuListCardPrice>
                                        <Text size="text32" weight="bold">
                                            {food.price}
                                        </Text>
                                        <Text size="text16">руб</Text>
                                    </MenuListCardPrice>
                                </MenuListCardWrapper>
                            </MenuListCard>
                        }
                        food={food}
                    />
                ))}
            </MenuListFoodInnerWrapper>
        </MenuListFoodWrapper>
    );
};

MenuListFood.propTypes = {
    list: PropTypes.array.isRequired,
};

export default MenuListFood;
