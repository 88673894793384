import { RED_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize24, fontSize32, secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const HomeWrapper = styled.div`
    padding-top: 72px;

    ${adaptive.minWidth.tablet} {
        padding-top: 88px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fslightbox-source-inner {
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        border-radius: 24px;

        overflow: hidden;
    }
`;

export const HomeSecondaryLogo = styled.div`
    position: relative;

    z-index: 1;

    img {
        width: 100%;
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -1;

        background-color: ${RED_COLOR};

        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;

        opacity: 0.3;
        filter: blur(24px);

        transform: translate(-50%, -50%);

        width: 112%;
        height: 112%;
    }
`;

export const HomeOfferWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 936px;

    margin: 56px auto 0;

    position: relative;

    h1 {
        text-align: center;

        letter-spacing: 0.02em;

        span {
            position: relative;
            z-index: 1;
            display: block;
            width: max-content;
            margin: auto;

            ${adaptive.minWidth.tablet} {
                display: inline;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 5%;
                right: 50%;
                transform: translateX(50%);
                z-index: -1;

                width: 110%;
                height: 12px;
                ${adaptive.minWidth.tablet} {
                    height: 20px;
                }

                background-color: ${RED_COLOR};
            }
        }
    }

    .home-down-scroll {
        position: absolute;
        left: 50%;
        bottom: 20%;
        ${adaptive.minWidth.tablet} {
            bottom: 25%;
        }

        transform: translateX(-50%);
    }

    .home-offer-marketplace {
        margin-top: 40px;
        padding-bottom: 176px;
        ${adaptive.minWidth.tablet} {
            padding-bottom: 268px;
        }
        ${adaptive.minWidth.desktop} {
            padding-bottom: 240px;
        }
    }

    .home-offer-img {
        width: 126px;
        height: 126px;
        ${adaptive.minWidth.tablet} {
            width: 162px;
            height: 162px;
        }

        margin-bottom: 31px;
        ${adaptive.minWidth.tablet} {
            margin-bottom: 40px;
        }
        ${adaptive.minWidth.desktop} {
            margin-bottom: 49px;
        }
    }

    .home-offer-text {
        margin-top: 20px;
        text-align: center;
        ${secondaryFontFamily}
    }
`;

export const HomeAdvantagesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    ${adaptive.minWidth.desktop} {
        flex-direction: row;
    }
`;

export const HomeAdvantagesImg = styled.img`
    max-width: 328px;
    margin-right: 0;
    ${adaptive.minWidth.tablet} {
        max-width: 476px;
    }
    ${adaptive.minWidth.desktop} {
        margin-right: 100px;
    }
`;

export const HomeAdvantagesList = styled.ul`
    max-width: 504px;

    display: flex;
    flex-direction: column;
    gap: 48px;

    margin-top: 80px;
    ${adaptive.minWidth.tablet} {
        margin-top: 120px;
    }
    ${adaptive.minWidth.desktop} {
        margin-top: 0;
    }
`;

export const HomeAdvantagesItem = styled.li`
    position: relative;

    padding-left: 26px;
    ${adaptive.minWidth.tablet} {
        padding-left: 44px;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;

        height: 100%;
        width: 10px;
        ${adaptive.minWidth.tablet} {
            width: 20px;
        }

        background-color: ${RED_COLOR};
    }

    p {
        ${adaptive.maxWidth.tablet} {
            ${fontSize32}
        }
    }
`;

export const HomeFeedback = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 187px 0 160px;

    ${adaptive.minWidth.tablet} {
        padding: 252px 0 240px;
    }

    img {
        max-width: 80px;
        margin-bottom: 32px;

        ${adaptive.minWidth.tablet} {
            max-width: 120px;
            margin-bottom: 48px;
        }
    }

    h4 {
        text-align: center;
        position: relative;

        max-width: 774px;

        margin-bottom: 32px;
        line-height: 120%;
        ${adaptive.minWidth.tablet} {
            margin-bottom: 44px;
        }

        span {
            position: relative;
            z-index: 1;

            &:after {
                content: "";
                position: absolute;
                bottom: 5%;
                right: 50%;
                transform: translateX(50%);
                z-index: -1;

                width: 110%;
                height: 20px;

                background-color: ${RED_COLOR};
            }
        }
    }
`;

export const HomeFeedbackBtn = styled.a`
    background-color: ${WHITE_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    border: none;

    width: 200px;
    height: 48px;

    p {
        text-align: center;
    }
`;
