import { SocialLink } from "@atoms/social-link";
import { socialLinksList } from "@molecules/social-links-list/helpers";
import { SocialLinksListWrapper } from "@molecules/social-links-list/theme";

import React from "react";

const SocialLinksList = () => {
    return (
        <SocialLinksListWrapper>
            {socialLinksList.map(({ link, src }, idx) => (
                <SocialLink key={idx} src={src} link={link} />
            ))}
        </SocialLinksListWrapper>
    );
};

export default SocialLinksList;
