import { title32, title48, title72 } from "@assets/styles/tokens/typography";

import styled, { SimpleInterpolation, css } from "styled-components";

export const getSize = (size) => {
    switch (size) {
        case "title32": {
            return css`
                ${title32}
            `;
        }
        case "title48": {
            return css`
                ${title48}
            `;
        }
        case "title72": {
            return css`
                ${title72}
            `;
        }
        default: {
            return;
        }
    }
};

export const TitleWrapper = styled.div`
    color: ${({ color }) => color};
    ${({ size }) => getSize(size)}
    &&& {
        ${({ weight }) => weight}
    }
`;
