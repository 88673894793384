export const stores = [
    {
        address: "ул. Уральская,4, 2 этаж",
        station: "м. Василеостровская",
        color: "#008000",
        id: "1",
        latitude: 59.95033,
        longitude: 30.26604,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 64800000, // 18:00
        },
        weekendHours: null,
        daysWeek: ["ПН", "ПТ"],
    },
    {
        address: "ул. Стахановцев, 10/3",
        station: "м. Новочеркасская",
        color: "#FF8C00",
        id: "2",
        latitude: 59.92686,
        longitude: 30.408381,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Малая Посадская, 19",
        station: "м. Горьковская",
        color: "#4A8CF7",
        id: "3",
        latitude: 59.958244,
        longitude: 30.330265,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Малая Посадская, 20",
        station: "м. Горьковская",
        color: "#4A8CF7",
        id: "4",
        latitude: 59.957336,
        longitude: 30.324459,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Карельский пер., 3Б",
        station: "м. Черная речка",
        color: "#4A8CF7",
        id: "6",
        latitude: 59.986798,
        longitude: 30.295413,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Наличная, 40/1",
        station: "м. Приморская",
        color: "#008000",
        id: "7",
        latitude: 59.949706,
        longitude: 30.23191,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Профессора Попова, 38",
        station: "м. Петроградская",
        color: "#4A8CF7",
        id: "8",
        latitude: 59.971213,
        longitude: 30.298631,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Наличная, 35/1",
        station: "м. Приморская",
        color: "#008000",
        id: "9",
        latitude: 59.941025,
        longitude: 30.232687,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 70200000, // 19:30
        },
        daysWeek: [],
    },
    {
        address: "Академика Лебедева, 7",
        station: "м. Площадь Ленина",
        color: "#FF0000",
        id: "10",
        latitude: 59.956166,
        longitude: 30.35168,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Шаврова, 9а",
        station: "м. Комендантский проспект",
        color: "#4B0082",
        id: "12",
        latitude: 60.023061,
        longitude: 30.232101,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Аптекарский проспект., 2",
        station: "м. Петроградская",
        color: "#008000",
        id: "15",
        latitude: 59.969213,
        longitude: 30.318319,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "22-я Линия В.О., 5",
        station: "м. Василеостровская",
        color: "#008000",
        id: "15",
        latitude: 59.932788,
        longitude: 30.263941,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Ломоносова, 1",
        station: "м. Гостиный Двор",
        color: "#4A8CF7",
        id: "16",
        latitude: 59.932017,
        longitude: 30.327775,
        break: {
            from: 50400000,
            to: 52200000,
        },
        workHours: {
            start: 36000000, // 10:00,
            end: 75600000, // 21:00
        },
        weekendHours: null,
        daysWeek: [],
    },
    {
        address: "Моховая ул., 26",
        station: "м. Чернышевская",
        color: "#FF0000",
        id: "18",
        latitude: 59.942034,
        longitude: 30.345467,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Пионерская, 21",
        station: "м. Чкаловская",
        color: "#4B0082",
        id: "19",
        latitude: 59.957808,
        longitude: 30.290454,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Средний пр., 100",
        station: "м. Приморская",
        color: "#008000",
        id: "20",
        latitude: 59.931655,
        longitude: 30.240651,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Наличная, 38к2",
        station: "м. Приморская",
        color: "#008000",
        id: "22",
        latitude: 59.946795,
        longitude: 30.230101,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Большой Сампсониевский пр., 80",
        station: "м. Лесная",
        color: "#FF0000",
        id: "23",
        latitude: 59.984832,
        longitude: 30.33356,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Старопетергофский, 42",
        station: "м. Нарвская",
        color: "#FF0000",
        id: "25",
        latitude: 59.903381,
        longitude: 30.277699,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Набережная Черной речки, 57",
        station: "м. Чёрная речка",
        color: "#4A8CF7",
        id: "27",
        latitude: 59.990314,
        longitude: 30.30216,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 79200000, // 22:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: ["ПН", "СБ"],
    },
    {
        address: "Туристская 24/42",
        station: "м. Беговая",
        color: "#008000",
        id: "29",
        latitude: 60.000009,
        longitude: 30.207836,
        break: null,
        workHours: {
            start: 30600000, // 08:30,
            end: 77400000, // 21:30
        },
        weekendHours: null,
        daysWeek: [],
    },
    {
        address: "9-я линия В.О., 48",
        station: "м. Василеостровская",
        color: "#008000",
        id: "30",
        latitude: 59.943913,
        longitude: 30.273941,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Кантемировская, 17",
        station: "м. Лесная",
        color: "#FF0000",
        id: "31",
        latitude: 59.984702,
        longitude: 30.34075,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Уточкина, 6",
        station: "м. Комендантский проспект",
        color: "#4B0082",
        id: "34",
        latitude: 60.011616,
        longitude: 30.262541,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Декабристов, 46",
        station: "м. Адмиралтейская",
        color: "#4B0082",
        id: "33",
        latitude: 59.924767,
        longitude: 30.289494,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Композиторов, 12",
        station: "м. Проспект Просвещения",
        color: "#4A8CF7",
        id: "36",
        latitude: 60.052185,
        longitude: 30.31323,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 75600000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Средний пр. В.О., 67",
        station: "м. Василеостровсая",
        color: "#008000",
        id: "38",
        latitude: 59.9394,
        longitude: 30.265362,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Большой проспект П.С., 70/72",
        station: "м. Петроградская",
        color: "#4A8CF7",
        id: "39",
        latitude: 59.962938,
        longitude: 30.307183,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Торфяная дорога 7Т",
        station: "м. Старая Деревня",
        color: "#4B0082",
        id: "40",
        latitude: 59.990394,
        longitude: 30.257569,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Бойцова, 4",
        station: "м. Спасская",
        color: "#FF8C00",
        id: "42",
        latitude: 59.922632,
        longitude: 30.310351,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Авиаконструкторов 2",
        station: "м. Комендантский Проспект",
        color: "#4B0082",
        id: "43",
        latitude: 60.009588,
        longitude: 30.246066,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Нарвский пр. 22",
        station: "м. Нарвская",
        color: "#FF0000",
        id: "44",
        latitude: 59.903063,
        longitude: 30.274449,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Льва Толстого 7",
        station: "м. Петроградская",
        color: "#4A8CF7",
        id: "46",
        latitude: 59.965326,
        longitude: 30.315068,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "наб. Чёрной речки, 49",
        station: "м. Чёрная речка",
        color: "#4A8CF7",
        id: "37",
        latitude: 59.985661,
        longitude: 30.305486,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Савушкина, 15",
        station: "м. Чёрная Речка",
        color: "#4A8CF7",
        id: "38",
        latitude: 59.985845,
        longitude: 30.291272,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "пр. Энгельса, 33/1",
        station: "м. Удельная",
        color: "#FF8C00",
        id: "39",
        latitude: 60.009606,
        longitude: 30.326307,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Королева, 27 к1",
        station: "м. Комендантский проспект",
        color: "#FF8C00",
        id: "47",
        latitude: 60.012801,
        longitude: 30.272188,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Кантемировская, 2",
        station: "м. Черная речка",
        color: "#4A8CF7",
        id: "48",
        latitude: 60.012801,
        longitude: 30.272188,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Промышленная, 5",
        station: "м. Нарвская",
        color: "#FF8C00",
        id: "1",
        latitude: 59.898741,
        longitude: 30.270157,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "ул. Ломаная, 5",
        station: "м. Московские Ворота",
        color: "#FF8C00",
        id: "1",
        latitude: 59.892105,
        longitude: 30.32607,
        break: null,
        workHours: {
            start: 28800000, // 08:00 workHours,
            end: 72000000, // 20:00
        },
        weekendHours: {
            start: 32400000, // 09:00 weekendHours,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
    {
        address: "Средний пр. В.О., 54",
        station: "м. Василеостровсая",
        color: "#008000",
        id: "38",
        latitude: 59.9402,
        longitude: 30.2695,
        break: null,
        workHours: {
            start: 28800000, // 08:00,
            end: 72000000, // 21:00
        },
        weekendHours: {
            start: 32400000, // 09:00,
            end: 75600000, // 21:00
        },
        daysWeek: [],
    },
];
