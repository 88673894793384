import { RED_COLOR, SOFT_GREY_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { secondaryFontFamily } from "@assets/styles/tokens/typography";
import { transitionDefault } from "@assets/styles/utils";

import styled from "styled-components";

export const AddressCardWrapper = styled.div`
    padding: 14px;

    background-color: ${SOFT_GREY_COLOR};

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

    cursor: pointer;

    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 8px;
        height: 100%;

        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:hover {
        &:before {
            background-color: ${RED_COLOR};
            ${transitionDefault}
        }
    }

    &:active {
        &:before {
            background-color: ${YELLOW_COLOR};
        }
    }

    p {
        ${secondaryFontFamily}
    }

    .address-card-title {
        margin-bottom: 8px;
    }
    .address-card-station {
        margin-bottom: 12px;
    }
    .address-card-status {
        margin-right: 16px;
    }
`;

export const AddressCardStation = styled.div`
    display: flex;

    svg {
        margin-right: 5px;

        rect {
            fill: ${({ color }) => color};
        }
    }
`;
