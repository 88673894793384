import styled from "styled-components";

export const SocialLinksListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    max-width: 220px;
`;
