import { RED_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import {
    fontSize16,
    fontSize24,
    fontSize32,
    fontSize48,
    text16,
    text24,
    text32,
    text48,
} from "@assets/styles/tokens/typography";
import { transitionDefault } from "@assets/styles/utils";

import styled from "styled-components";

export const MenuListDrinkWrapper = styled.div``;

export const MenuListDrinkHeader = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const MenuListDrinkName = styled.button`
    background-color: transparent;
    border: none;

    ${transitionDefault}

    &:hover {
        p {
            color: ${RED_COLOR};
            ${transitionDefault}
        }
    }
`;

export const MenuListDrinkBody = styled.div`
    margin-top: 80px;
`;

export const MenuListDrinkRow = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

export const MenuListDrinkTitle = styled.div`
    display: flex;
    align-items: flex-end;

    margin-right: 20px;
`;

export const MenuListDrinkUnique = styled.div`
    width: 133px;

    padding: 6px 0 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${RED_COLOR};

    ${adaptive.minWidth.tablet} {
        width: 173px;
        padding: 10px 0 5px;
    }
`;

export const ColumnWrapper = styled.div`
    min-width: 120px;
    padding: 0;
    ${adaptive.minWidth.desktop} {
        min-width: 168px;
        padding: 0 12px;
    }
    &.column-body-row {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        ${adaptive.minWidth.desktop} {
            flex-direction: row;
        }
    }

    &.column-header {
        display: flex;
        align-items: center;
        flex-direction: column;

        ${adaptive.minWidth.desktop} {
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
        }

        p {
            line-height: 1;
            margin-top: 24px;
            ${adaptive.minWidth.desktop} {
                margin-top: 0;
                line-height: 0.5;
            }
        }
    }
`;

export const OneColumnWrapper = styled(ColumnWrapper)`
    flex-grow: 1;
    display: flex;
    align-items: center;

    padding-left: 0;

    flex-wrap: wrap;
`;

export const TwoColumnWrapper = styled(ColumnWrapper)`
    &.column-header {
        img {
            max-width: 44px;
        }
    }
`;

export const ThreeColumnWrapper = styled(ColumnWrapper)`
    &.column-header {
        img {
            max-width: 58px;
        }
    }
`;

export const FourColumnWrapper = styled(ColumnWrapper)`
    padding-right: 0;
`;
