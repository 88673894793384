import QrImage from "@assets/image/QrPopup.svg";
import QrImageDev from "@assets/image/QrPopupDev.svg";
import QrImageProd from "@assets/image/QrPopupProd.svg";
import Popup from "@atoms/popup/popup";
import { Text } from "@atoms/text";
import { QrPopupCode, QrPopupInnerWrapper, QrPopupText, QrPopupTitle, QrPopupWrapper } from "@molecules/qr-popup/theme";

import React from "react";
import { ReactSVG } from "react-svg";

import PropTypes from "prop-types";

import CloseBtnPopup from "../../atoms/close-btn-popup/close-btn-popup";

const QrPopup = ({ trigger }) => {
    return (
        <Popup trigger={trigger}>
            {(close) => (
                <QrPopupWrapper>
                    <CloseBtnPopup clickHandler={() => close()} />
                    <QrPopupInnerWrapper>
                        <QrPopupTitle size="text24">
                            сканируйте{" "}
                            <Text size="text24" tag="span">
                                QR код,
                            </Text>{" "}
                            чтобы перейти к установке
                        </QrPopupTitle>
                        <QrPopupText size="text12" color="#C7C7CC">
                            Доступно для App Store и Google Play
                        </QrPopupText>
                        <QrPopupCode>
                            <ReactSVG src={QrImageProd} />
                        </QrPopupCode>
                    </QrPopupInnerWrapper>
                </QrPopupWrapper>
            )}
        </Popup>
    );
};

QrPopup.propTypes = {
    trigger: PropTypes.node.isRequired,
};

export default QrPopup;
