import { StockBlock, StockInnerWrapper, StockWrapper } from "@molecules/stock/theme";

import React from "react";

import PropTypes from "prop-types";

const Stock = ({ title, text, src }) => {
    return (
        <StockWrapper>
            <StockInnerWrapper>
                <StockBlock>
                    {title}
                    {text}
                </StockBlock>
                <img src={src} alt="" />
            </StockInnerWrapper>
        </StockWrapper>
    );
};

Stock.propTypes = {
    title: PropTypes.node.isRequired,
    text: PropTypes.node,
    src: PropTypes.string.isRequired,
};

export default Stock;
