import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize16, fontSize24, fontSize32, fontSize48 } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const MenuListOtherDrinkWrapper = styled.div`
    margin-top: 40px;
    padding-top: 40px;

    border-top: 4px solid #646468;
`;

export const MenuListOtherDrinkTitle = styled.div`
    margin-bottom: 40px;

    display: flex;
    justify-content: flex-end;
`;

export const MenuListOtherDrinkInnerWrapper = styled.div``;

export const MenuListOtherDrinkRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 20px 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .column-body-price {
        ${fontSize32}
        ${adaptive.minWidth.desktop} {
            ${fontSize48}
        }
    }

    .column-body-name-currency {
        ${fontSize16}
        ${adaptive.minWidth.desktop} {
            ${fontSize24}
        }
    }
`;

export const MenuListOtherDrinkColumn = styled.div`
    min-width: auto;
    padding-left: 15px;
    ${adaptive.minWidth.desktop} {
        min-width: 168px;
    }
`;
