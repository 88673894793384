import { fontWeightBold, fontWeightMedium, fontWeightNormal } from "@assets/styles/tokens/typography";

import React from "react";

import PropTypes from "prop-types";

import { TitleWrapper } from "./theme";

const Title = ({ children, tag = "h5", size = "title72", color, className, weight = "bold" }) => {
    const resultWeight = {
        medium: fontWeightMedium,
        normal: fontWeightNormal,
        bold: fontWeightBold,
    }[weight];
    return (
        <TitleWrapper className={className} color={color} size={size} as={tag} weight={resultWeight}>
            {children}
        </TitleWrapper>
    );
};

Title.propTypes = {
    children: PropTypes.node.isRequired,
    tag: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    className: PropTypes.string,
};

export default Title;
