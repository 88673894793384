import styled from "styled-components";

export const HeaderDrawerWrapper = styled.div`
    padding-top: 138px;

    display: flex;
    flex-direction: column;

    height: 100%;

    & > div {
        flex-grow: 1;
    }
    ul {
        gap: 32px;
    }
`;
