import ArrowDown from "@assets/icons/arrow_down.svg";
import AdvantagesImage from "@assets/image/advantages.png";
import CoffeeCup from "@assets/image/coffee-cup.svg";
import MainLogo from "@assets/image/label.svg";
import { BLACK_COLOR } from "@assets/styles/tokens/colors";
import { Section } from "@atoms/section";
import { Text } from "@atoms/text";
import { Title } from "@atoms/title";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import { SliderPhotos } from "@molecules/slider-photos";
import { SliderStock } from "@molecules/slider-stock";
import { Menu } from "@organisms/menu";
import { DeleteFormText, DeleteOfferWrapper, DeleteTextbox, DeleteWrapper } from "@pages/deleteAccount/theme";
import {
    HomeAdvantagesImg,
    HomeAdvantagesItem,
    HomeAdvantagesList,
    HomeAdvantagesWrapper,
    HomeFeedback,
    HomeFeedbackBtn,
    HomeOfferWrapper,
    HomeSecondaryLogo,
    HomeWrapper,
} from "@pages/home/theme";
import { Page } from "@templates/page";

import React, { useContext } from "react";
import { Link } from "react-scroll";

const DeleteAccount = () => {
    return (
        <Page>
            <DeleteWrapper>
                <Section>
                    <DeleteOfferWrapper>
                        <Title className="delete-title" tag="h1" weight="normal">
                            удаление аккаунта <br />в
                            <Title tag="span" weight="bold">
                                мобильном приложении
                            </Title>
                        </Title>
                        <DeleteTextbox>
                            <Text className="delete-list-text" color="#C7C7CC">
                                Пользователи мобильного приложения ООО &#34;Кофебон Рус&#34; на устройствах с системой
                                Android могут запросить удаление своего аккаунта не только через само мобильное
                                приложение, но и через данный сайт.
                            </Text>
                            <Text className="delete-list-text" color="#C7C7CC">
                                Для этого необходимо заполнить обязательные поля формы обратной связи, отправить запрос
                                и ожидать его обработки администратором. В течение 10 дней мы свяжемся с Вами для
                                подтверждения удаления аккаунта мобильного приложения.
                            </Text>
                            <Text className="delete-list-text" color="#C7C7CC">
                                После удаления вашего аккаунта в мобильном приложении ООО &#34;Кофебон Рус&#34; будут
                                также удалены данные о Вас, в том числе: уведомления, отклики на вакансию и резюме,
                                документы по трудоустройству, уведомления и изменения часового пояса, заявки на кадровые
                                процессы и прочее.
                            </Text>
                        </DeleteTextbox>
                        <DeleteFormText>
                            Для отправки запроса на удаление заполните <a href="#">форму</a>.
                        </DeleteFormText>
                    </DeleteOfferWrapper>
                </Section>
            </DeleteWrapper>
        </Page>
    );
};

export default DeleteAccount;
