import { adaptive } from "@assets/styles/tokens/screen";
import { transitionDefault } from "@assets/styles/utils";

import styled from "styled-components";

export const HeaderWrapper = styled.header`
    position: fixed;
    z-index: 1001;

    backdrop-filter: blur(30px);

    width: 100%;
    ${transitionDefault}

    &.scroll {
        background: rgba(0, 0, 0, 0.6);
        ${transitionDefault}
    }
`;

export const HeaderInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;

    &.show-drawer {
        height: 100vh;
    }

    & > div:last-child {
        flex-grow: 1;
    }
`;

export const HeaderStyled = styled.div`
    height: 72px;

    ${adaptive.minWidth.tablet} {
        height: 88px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
        display: flex;
        align-items: center;

        img {
            max-width: 67px;
            ${adaptive.minWidth.tablet} {
                max-width: 81px;
            }
        }
    }
`;
