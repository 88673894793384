import MarkerLogo from "@assets/image/label.svg";
import { MapWrapper } from "@atoms/map/theme";
import MapContext from "@context/map/mapContext";

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Clusterer, Placemark, Map as YMap, YMaps, ZoomControl } from "react-yandex-maps";

import PropTypes from "prop-types";

export const Map = ({ storesList, setMarkerAddress }) => {
    const [defaultState] = useState({
        center: [59.923013148014775, 30.29196468139644],
        zoom: 12,
    });
    const [coordinatesMarker, setCoordinatesMarker] = useState([]);
    const [_, setMapInstance] = useContext(MapContext);
    const map = useRef(null);

    const handleClickPlaceMarker = useCallback(
        (event) => {
            storesList.map((store) => {
                if (store.latitude === event.originalEvent.target.geometry["_coordinates"][0]) {
                    setMarkerAddress(store);
                    map.current.setCenter(event.originalEvent.target.geometry["_coordinates"]);
                    map.current.setZoom(16);
                }
            });
        },
        [setMarkerAddress, storesList],
    );

    useEffect(() => {
        if (storesList.length) setCoordinatesMarker(storesList.map((store) => [store.latitude, store.longitude]));
    }, [storesList]);

    return (
        <MapWrapper>
            <YMaps className="map">
                <YMap
                    defaultState={defaultState}
                    width="100%"
                    height="100%"
                    instanceRef={map}
                    onLoad={() => map?.current && setMapInstance(map.current)}
                >
                    <ZoomControl
                        options={{
                            size: "small",
                            position: { top: "auto", right: 20 },
                        }}
                    />
                    <Clusterer
                        options={{
                            gridSize: 70,
                            groupByCoordinates: false,
                        }}
                    >
                        {coordinatesMarker.length &&
                            coordinatesMarker.map((coordinate, idx) => (
                                <Placemark
                                    onClick={handleClickPlaceMarker}
                                    geometry={coordinate}
                                    key={idx}
                                    options={{ iconLayout: "default#image", iconImageHref: MarkerLogo }}
                                />
                            ))}
                    </Clusterer>
                </YMap>
            </YMaps>
        </MapWrapper>
    );
};

Map.propTypes = {
    storesList: PropTypes.array.isRequired,
    setMarkerAddress: PropTypes.func.isRequired,
};

export default Map;
