import { RED_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const FooterWrapper = styled.footer`
    background: rgba(255, 255, 255, 0.15);

    padding-bottom: 24px;
`;

export const FooterInnerWrapper = styled.div`
    .home-footer-img {
        width: 126px;
        height: 126px;
        margin-bottom: 47px;
        ${adaptive.minWidth.tablet} {
            width: 135px;
            height: 135px;
            margin-bottom: 72px;
        }
    }
`;

export const FooterSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.footer-section-navigation {
        flex-direction: column;
        align-items: flex-start;
        ${adaptive.minWidth.desktop} {
            flex-direction: row;
            align-items: center;
        }
        ul {
            margin-bottom: 32px;
            flex-direction: column;
            gap: 32px;
            ${adaptive.minWidth.tablet} {
                flex-direction: row;
                gap: 64px;
            }
            ${adaptive.minWidth.desktop} {
                margin-bottom: 0;
            }
        }
    }

    &.home-footer-section-one {
        flex-direction: column;
        ${adaptive.minWidth.tablet} {
            flex-direction: row;
        }
    }

    .footer-marketplace-text {
        letter-spacing: 1px;
        span {
            position: relative;
            z-index: 1;

            &:after {
                content: "";
                position: absolute;
                bottom: 5%;
                right: 50%;
                transform: translateX(50%);
                z-index: -1;

                width: 110%;
                height: 8px;

                background-color: ${RED_COLOR};
            }
        }
    }

    .footer-marketplace {
        margin-top: 24px;
        margin-bottom: 56px;
        ${adaptive.minWidth.tablet} {
            margin-bottom: 0;
        }
    }

    .footer-left-section {
        margin-top: 47px;
        ${adaptive.minWidth.tablet} {
            margin-top: 55px;
        }
    }

    .footer-right-section {
        span {
            white-space: pre;
        }
    }

    .footer-copyright {
        &-text {
            ${secondaryFontFamily};
        }
    }
`;

export const FooterBlock = styled.div`
    &.footer-copyright {
        margin-top: 64px;

        .footer-section-copyright {
            flex-direction: column;
            justify-content: start;
            align-items: start;

            & > p {
                margin-bottom: 10px;

                ${adaptive.minWidth.tablet} {
                    margin: 0;
                }
            }

            ${adaptive.minWidth.tablet} {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .footer-info {
                line-height: 1.3;
                text-align: left;

                ${adaptive.minWidth.tablet} {
                    max-width: 40%;
                    text-align: right;
                }
            }
        }
    }
`;
