import ButtonMore from "@atoms/button-more/button-more";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import MinimalListDrinkMinimal from "@molecules/menu-list-drink-minimal/menu-list-drink-minimal";
import MenuListDrink from "@molecules/menu-list-drink/menu-list-drink";
import MenuListOtherDrink from "@molecules/menu-list-other-drink/menu-list-other-drink";
import { MenuListDrinkContainerWrapper } from "@organisms/menu-list-drink-container/theme";
import { menuListCoffee, menuListDrinks, menuListOtherDrinks } from "@organisms/menu/helpers";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

const MenuListDrinkContainer = ({ activeTab }) => {
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    const [isShowAllList, setIsShowAllList] = useState(false);
    const [listDrinks, setListDrinks] = useState([]);

    const checkDrinksList = useCallback((drink) => {
        switch (drink) {
            case "coffee":
                return menuListCoffee;
            case "drinks":
                return menuListDrinks;
            default:
                return [];
        }
    }, []);

    const clickMoreHandler = useCallback(() => {
        if (isShowAllList) {
            setListDrinks(checkDrinksList(activeTab));
            setIsShowAllList(false);
        } else {
            setListDrinks(checkDrinksList(activeTab).slice(0, 6));
            setIsShowAllList(true);
        }
    }, [activeTab, checkDrinksList, isShowAllList]);

    const isShowMoreBtn = useMemo(() => checkDrinksList(activeTab).length > 6, [activeTab, checkDrinksList]);
    const isShowMenuListOtherDrinks = useMemo(() => {
        return checkDrinksList("drinks").length < 6 || (checkDrinksList("drinks").length > 6 && !isShowAllList);
    }, [checkDrinksList, isShowAllList]);

    useEffect(() => {
        if (isShowMoreBtn) {
            setListDrinks(checkDrinksList(activeTab).slice(0, 6));
            setIsShowAllList(true);
            return;
        }

        setListDrinks(checkDrinksList(activeTab));
        setIsShowAllList(false);
    }, [activeTab, checkDrinksList, isShowMoreBtn]);

    const menuListDrinkComponent = useMemo(() => {
        return typeBreakpoint !== "mobile" ? (
            <MenuListDrink list={listDrinks} />
        ) : (
            <MinimalListDrinkMinimal list={listDrinks} />
        );
    }, [listDrinks, typeBreakpoint]);

    return (
        <MenuListDrinkContainerWrapper>
            {menuListDrinkComponent}
            {isShowMenuListOtherDrinks && activeTab === "drinks" && <MenuListOtherDrink list={menuListOtherDrinks} />}
            {isShowMoreBtn && <ButtonMore clickHandler={clickMoreHandler} isActiveMoreState={isShowAllList} />}
        </MenuListDrinkContainerWrapper>
    );
};

MenuListDrinkContainer.propTypes = {
    activeTab: PropTypes.string.isRequired,
};

export default MenuListDrinkContainer;
