import AppStore from "@assets/image/App_store.svg";
import GooglePlay from "@assets/image/Google_play.svg";
import QR from "@assets/image/QrBtn.svg";
import { MarketPlaceButton, MarketPlaceQrButton, MarketPlaceWrapper } from "@atoms/market-place/theme";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";

import React, { useCallback, useContext } from "react";

import PropTypes from "prop-types";

import QrPopup from "../../molecules/qr-popup/qr-popup";

const MarketPlace = ({ className = "" }) => {
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    const clickHandlerMarketPlace = useCallback((linkMarketPlace) => {
        location.href = linkMarketPlace;
    }, []);

    return (
        <MarketPlaceWrapper className={className}>
            <MarketPlaceButton
                onClick={() => clickHandlerMarketPlace("https://apps.apple.com/ru/app/coffee-bon/id6446148716")}
            >
                <img src={AppStore} alt="" />
            </MarketPlaceButton>
            <MarketPlaceButton
                onClick={() =>
                    clickHandlerMarketPlace(
                        "https://play.google.com/store/apps/details?id=info.opens.coffeebon&hl=ru&gl=US",
                    )
                }
            >
                <img src={GooglePlay} alt="" />
            </MarketPlaceButton>
            {typeBreakpoint === "desktop" && (
                <QrPopup
                    trigger={
                        <MarketPlaceQrButton>
                            <img src={QR} alt="" />
                        </MarketPlaceQrButton>
                    }
                />
            )}
        </MarketPlaceWrapper>
    );
};

MarketPlace.propTypes = {
    className: PropTypes.string,
};

export default MarketPlace;
