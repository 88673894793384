import MapContext from "@context/map/mapContext";

import React from "react";
import { useState } from "react";

import PropTypes from "prop-types";

const MapContextProvider = (props) => {
    const [mapInstance, setMapInstance] = React.useState();

    return <MapContext.Provider value={[mapInstance, setMapInstance]}>{props.children}</MapContext.Provider>;
};

MapContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MapContextProvider;
