import SizeImg from "@assets/icons/size.svg";
import NameDrink from "@atoms/name-drink/name-drink";
import { PriceDrink } from "@atoms/price-drink";
import { Text } from "@atoms/text";
import {
    MinimalListDrinkMinimalColumnGrande,
    MinimalListDrinkMinimalColumnMedium,
    MinimalListDrinkMinimalColumnUno,
    MinimalListDrinkMinimalInnerWrapper,
    MinimalListDrinkMinimalItem,
    MinimalListDrinkMinimalRow,
    MinimalListDrinkMinimalWrapper,
    MinimalListDrinkTitle,
    MinimalListDrinkTitleWrapper,
} from "@molecules/menu-list-drink-minimal/theme";
import { MenuListDrinkUnique } from "@molecules/menu-list-drink/theme";

import React from "react";

import PropTypes from "prop-types";

const MinimalListDrinkMinimal = ({ list }) => {
    return (
        <MinimalListDrinkMinimalWrapper>
            {list.map((drink, idx) => (
                <MinimalListDrinkMinimalItem key={idx}>
                    <MinimalListDrinkTitleWrapper>
                        <MinimalListDrinkTitle>
                            <NameDrink drink={drink} positionTooltip="top left" offsetYTooltip={20} />
                            {drink.description && <Text size="text20">{drink.description}</Text>}
                        </MinimalListDrinkTitle>
                        {drink.unique && (
                            <MenuListDrinkUnique>
                                <Text size="text16" weight="bold">
                                    Только в Coffee bon
                                </Text>
                            </MenuListDrinkUnique>
                        )}
                    </MinimalListDrinkTitleWrapper>
                    <MinimalListDrinkMinimalInnerWrapper>
                        <MinimalListDrinkMinimalRow>
                            <MinimalListDrinkMinimalColumnUno>
                                <img src={SizeImg} alt="" />
                                <Text size="text20" className="menu-list-drinks-minimal-size">
                                    uno
                                </Text>
                            </MinimalListDrinkMinimalColumnUno>
                            <MinimalListDrinkMinimalColumnMedium>
                                <img src={SizeImg} alt="" />
                                <Text size="text20" className="menu-list-drinks-minimal-size">
                                    media
                                </Text>
                            </MinimalListDrinkMinimalColumnMedium>
                            <MinimalListDrinkMinimalColumnGrande>
                                <img src={SizeImg} alt="" />
                                <Text size="text20" className="menu-list-drinks-minimal-size">
                                    grande
                                </Text>
                            </MinimalListDrinkMinimalColumnGrande>
                        </MinimalListDrinkMinimalRow>
                        <MinimalListDrinkMinimalRow>
                            <MinimalListDrinkMinimalColumnUno>
                                <PriceDrink price={drink.uno} />
                            </MinimalListDrinkMinimalColumnUno>
                            <MinimalListDrinkMinimalColumnMedium>
                                <PriceDrink price={drink.media} />
                            </MinimalListDrinkMinimalColumnMedium>
                            <MinimalListDrinkMinimalColumnGrande>
                                <PriceDrink price={drink.grande} />
                            </MinimalListDrinkMinimalColumnGrande>
                        </MinimalListDrinkMinimalRow>
                    </MinimalListDrinkMinimalInnerWrapper>
                </MinimalListDrinkMinimalItem>
            ))}
        </MinimalListDrinkMinimalWrapper>
    );
};

MinimalListDrinkMinimal.propTypes = {
    list: PropTypes.array.isRequired,
};

export default MinimalListDrinkMinimal;
