import { RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { transitionDefault } from "@assets/styles/utils";

import styled from "styled-components";

export const MarketPlaceWrapper = styled.div`
    display: flex;
    gap: 12px;
    ${adaptive.minWidth.tablet} {
        gap: 16px;
    }
`;

export const MarketPlaceButton = styled.button`
    background: ${WHITE_COLOR};

    border: none;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;

    width: 158px;
    ${adaptive.minWidth.tablet} {
        width: 180px;
    }
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${RED_COLOR};
        ${transitionDefault}
    }
    &:active {
        background-color: ${YELLOW_COLOR};
    }
`;

export const MarketPlaceQrButton = styled.button`
    background: ${WHITE_COLOR};

    border: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;

    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${RED_COLOR};
        ${transitionDefault}
    }
    &:active {
        background-color: ${YELLOW_COLOR};
    }
`;
