import Logo from "@assets/image/Logo.svg";
import { defaultStyle, transitionStyles } from "@assets/styles/utils";
import { Section } from "@atoms/section";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import { HeaderDrawer } from "@molecules/header-drawer";
import { NavigationList } from "@molecules/navigation-list";
import { SocialLinksList } from "@molecules/social-links-list";
import { HeaderInnerWrapper, HeaderStyled, HeaderWrapper } from "@organisms/header/theme";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Transition } from "react-transition-group";

import HamburgerBtn from "../../atoms/hamburger-btn/hamburger-btn";

const Header = () => {
    const [isTop, setIsTop] = useState(false);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    const nodeRef = React.useRef(null);

    const headerClasses = useMemo(() => {
        return !isTop ? "scroll" : "";
    }, [isTop]);

    const scrollHandler = useCallback(() => {
        setIsTop(!window.pageYOffset);
    }, []);

    const scrollToTop = useCallback(() => {
        scroll.scrollToTop({
            smooth: true,
            duration: 100,
        });
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    }, [scrollHandler]);

    const showDrawerClasses = useMemo(() => {
        return isShowDrawer ? "show-drawer" : "";
    }, [isShowDrawer]);

    const handleClickNavigationItem = useCallback(() => {
        setIsShowDrawer(!isShowDrawer);
    }, [isShowDrawer]);

    return (
        <>
            <HeaderWrapper className={headerClasses}>
                <Section>
                    <HeaderInnerWrapper className={showDrawerClasses}>
                        <HeaderStyled>
                            <Link to="/" className="header-logo" onClick={scrollToTop}>
                                <img src={Logo} alt="" />
                            </Link>
                            {typeBreakpoint === "desktop" && <NavigationList isActiveClass={true} />}
                            {typeBreakpoint === "desktop" && <SocialLinksList />}
                            {typeBreakpoint !== "desktop" && (
                                <HamburgerBtn isShowDrawer={isShowDrawer} setIsShowDrawer={handleClickNavigationItem} />
                            )}
                        </HeaderStyled>
                        <Transition in={isShowDrawer} unmountOnExit timeout={300} nodeRef={nodeRef}>
                            {(state) => (
                                <div
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                >
                                    <HeaderDrawer handleClickNavigationItem={handleClickNavigationItem} />
                                </div>
                            )}
                        </Transition>
                    </HeaderInnerWrapper>
                </Section>
            </HeaderWrapper>
        </>
    );
};

export default Header;
