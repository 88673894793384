import { secondaryFontFamily } from "@assets/styles/tokens/typography";
import { Text } from "@atoms/text";

import styled from "styled-components";

export const CurrentAddressSidebarWrapper = styled.div`
    padding-right: 24px;
`;

export const CurrentAddressSidebarBackWrapper = styled.div``;

export const CurrentAddressSidebarBackBtn = styled.button`
    background-color: transparent;
    border: none;
    margin-right: 12px;

    display: flex;
    align-items: center;

    p {
        margin-left: 12px;
        ${secondaryFontFamily}
    }
`;

export const CurrentAddressSidebarInnerWrapper = styled.div`
    margin-top: 25px;
`;

export const CurrentAddressSidebarImageStore = styled.div`
    height: 200px;

    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

    margin-bottom: 24px;
`;

export const CurrentAddressSidebarStreet = styled(Text)`
    ${secondaryFontFamily}
`;

export const CurrentAddressSidebarStation = styled.div`
    display: flex;

    margin-top: 17px;
    ${secondaryFontFamily}

    svg {
        margin-right: 5px;

        rect {
            fill: ${({ color }) => color};
        }
    }
`;

export const CurrentAddressSidebarWorked = styled.div`
    display: flex;
    gap: 12px;

    margin-top: 13px;
    margin-bottom: 16px;

    padding-bottom: 16px;

    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.1);

    p {
        ${secondaryFontFamily}
    }
`;

export const CurrentAddressSidebarTime = styled.div`
    padding-bottom: 16px;
    margin-bottom: 16px;

    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.1);
`;

export const CurrentAddressSidebarTitle = styled(Text)`
    margin-bottom: 12px;
    ${secondaryFontFamily}
`;

export const CurrentAddressSidebarBreak = styled(Text)`
    position: relative;
    color: #c7c7cc;
    span {
        vertical-align: super;
    }
    ${secondaryFontFamily}
`;

export const CurrentAddressSidebarTimeText = styled(Text)`
    color: #c7c7cc;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
    &.available-break {
        position: relative;
        span {
            position: absolute;
            top: 0;

            line-height: 0;
            font-size: 17px;
        }
    }
    ${secondaryFontFamily}
`;

export const CurrentAddressSidebarContact = styled.div`
    padding-bottom: 16px;
    margin-bottom: 16px;

    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.1);
`;

export const CurrentAddressSidebarContactPhone = styled(Text)`
    ${secondaryFontFamily}
`;
