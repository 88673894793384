import { adaptive, breakpoints } from "@assets/styles/tokens/screen";

import { css } from "styled-components";

const duration = 300;

export const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
};

export const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

export const transitionDefault = css`
    transition: all 0.3s ease;
`;

export const mainContentWrapper = css`
    margin: 0;
    padding: 0 15px;
    width: 100%;
    ${adaptive.minWidth.tablet} {
        padding: 0 32px;
    }
    ${adaptive.minWidth.desktop} {
        box-sizing: content-box;
        max-width: ${breakpoints.desktop}px;
        margin: 0 auto;
    }
`;
