import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";

import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

const TypeBreakpointContextProvider = ({ children }) => {
    const [typeBreakpoint, setTypeBreakpoint] = useState("desktop");

    const resizeHandler = useCallback(() => {
        if (window.innerWidth < 1128 && window.innerWidth > 359) setTypeBreakpoint("tablet");
        if (window.innerWidth < 768) setTypeBreakpoint("mobile");
        if (window.innerWidth > 1128) setTypeBreakpoint("desktop");
    }, [setTypeBreakpoint]);

    useEffect(() => {
        resizeHandler();
        window.addEventListener("resize", () => resizeHandler());

        return () => window.removeEventListener("resize", () => resizeHandler());
    }, [resizeHandler]);

    return (
        <TypeBreakpointContext.Provider value={{ typeBreakpoint, setTypeBreakpoint }}>
            {children}
        </TypeBreakpointContext.Provider>
    );
};

TypeBreakpointContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TypeBreakpointContextProvider;
