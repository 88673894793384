import EmptyFoodImage from "@assets/image/food/empty_food.svg";
import { adaptive } from "@assets/styles/tokens/screen";
import { fontSize12, fontSize14, secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled, { css } from "styled-components";

const getImageFood = (src) => {
    if (src) {
        return css`
            background-image: url(${({ src }) => src});
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            background-position: center center;
        `;
    }
    return css`
        background-color: rgba(255, 255, 255, 0.15);
        background-image: url(${EmptyFoodImage});
        background-repeat: no-repeat;
        background-position: center center;
    `;
};

export const MenuListFoodWrapper = styled.div``;

export const MenuListFoodInnerWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 12px;
    ${adaptive.minWidth.tablet} {
        grid-gap: 24px 20px;
        grid-template-columns: repeat(3, 1fr);
    }
    ${adaptive.minWidth.desktop} {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 64px 24px;
    }
`;

export const MenuListCard = styled.div`
    object-fit: cover;

    cursor: pointer;

    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MenuListCardWrapper = styled.div`
    flex-grow: 1;

    display: flex;
    flex-direction: column;
`;

export const MenuListCardImage = styled.div`
    width: 100%;
    height: 158px;

    margin-bottom: 24px;

    ${adaptive.minWidth.tablet} {
        height: 221px;
    }
    ${adaptive.minWidth.desktop} {
        width: 264px;
        height: 264px;
    }

    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;

    ${({ src }) => getImageFood(src)}
`;

export const MenuListCardTitle = styled.div`
    h5 {
        line-height: 38.4px;
    }
`;

export const MenuListCardDescription = styled.div`
    margin-top: 8px;

    p {
        line-height: 19.6px;
        height: 59px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        ${secondaryFontFamily}
        ${fontSize12}
        ${adaptive.minWidth.tablet} {
            ${fontSize14}
        }
    }
`;

export const MenuListCardPrice = styled.div`
    flex-grow: 1;
    margin-top: 24px;

    display: flex;
    align-items: flex-end;
    p {
        &:first-child {
            line-height: 0.8;
            margin-right: 5px;
        }
    }
`;
