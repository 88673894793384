import { MenuTabs } from "@molecules/menu-tabs";
import { MenuListDrinkContainer } from "@organisms/menu-list-drink-container";
import { MenuListFoodContainer } from "@organisms/menu-list-food-container";
import { MenuWrapper } from "@organisms/menu/theme";

import React, { useMemo, useState } from "react";

const Menu = () => {
    const [activeTab, setActiveTab] = useState("coffee");

    const isDrinkActiveTab = useMemo(() => {
        return activeTab !== "food" && activeTab !== "desserts";
    }, [activeTab]);

    return (
        <MenuWrapper id="menu">
            <MenuTabs setActiveTabHandler={setActiveTab} activeTab={activeTab} />
            {isDrinkActiveTab ? (
                <MenuListDrinkContainer activeTab={activeTab} />
            ) : (
                <MenuListFoodContainer activeTab={activeTab} />
            )}
        </MenuWrapper>
    );
};

export default Menu;
