import { CloseBtnPopup } from "@atoms/close-btn-popup";
import { Popup } from "@atoms/popup";
import { Text } from "@atoms/text";
import { Title } from "@atoms/title";
import {
    FoodPopupDescription,
    FoodPopupImage,
    FoodPopupInnerWrapper,
    FoodPopupPrice,
    FoodPopupTitle,
    FoodPopupWrapper,
} from "@molecules/food-popup/theme";

import React from "react";

import PropTypes from "prop-types";

const FoodPopup = ({ trigger, food }) => {
    return (
        <Popup trigger={trigger} className="food-popup">
            {(close) => (
                <FoodPopupWrapper>
                    <CloseBtnPopup clickHandler={() => close()} />
                    <FoodPopupInnerWrapper>
                        <FoodPopupImage src={food.src} />
                    </FoodPopupInnerWrapper>
                    <FoodPopupInnerWrapper className="content-wrapper">
                        <FoodPopupTitle>
                            <Title size="title32" weight="bold">
                                {food.title}
                            </Title>
                        </FoodPopupTitle>
                        <FoodPopupDescription>
                            <Text size="text14">{food.description}</Text>
                        </FoodPopupDescription>
                        <FoodPopupPrice>
                            <Text size="text32" weight="bold">
                                {food.price}
                            </Text>
                            <Text size="text16">руб</Text>
                        </FoodPopupPrice>
                    </FoodPopupInnerWrapper>
                </FoodPopupWrapper>
            )}
        </Popup>
    );
};

FoodPopup.propTypes = {
    trigger: PropTypes.node.isRequired,
    food: PropTypes.object.isRequired,
};

export default FoodPopup;
