import { WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";

import styled from "styled-components";

export const HamburgerBtnWrapper = styled.div`
    .hamburger-inner {
        background-color: ${WHITE_COLOR} !important;

        height: 2px;
        width: 100%;

        &:before,
        &:after {
            background-color: ${WHITE_COLOR} !important;
            height: 2px;
            width: 100%;
        }

        &:before {
            top: -7px;
        }

        &:after {
            bottom: -7px;
        }
    }

    .hamburger-box {
        width: 20px;
        height: 14px;
        ${adaptive.minWidth.tablet} {
            width: 24px;
            height: 17px;
        }
    }

    .hamburger {
        padding: 0;

        width: 48px;
        height: 48px;
        ${adaptive.minWidth.tablet} {
            width: 56px;
            height: 56px;
        }
        background: rgba(255, 255, 255, 0.15);

        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
