import { Slider } from "@atoms/slider";
import TypeBreakpointContext from "@context/type-breakpoint/type-breakpoint-context";
import { photos } from "@molecules/slider-photos/helpers";
import { SliderPhotosItem, SliderPhotosWrapper } from "@molecules/slider-photos/theme";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import FsLightbox from "fslightbox-react";

const SliderPhotos = () => {
    const { typeBreakpoint } = useContext(TypeBreakpointContext);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1,
    });

    const photosItem = useMemo(() => {
        return photos.map((src, idx) => (
            <SliderPhotosItem key={idx}>
                <img src={src} alt="" />
            </SliderPhotosItem>
        ));
    }, []);

    const countSlidesPerView = useMemo(() => {
        switch (typeBreakpoint) {
            case "desktop":
                return 7;
            case "tablet":
                return 3;
            case "mobile":
                return 2;
            default:
                return 7;
        }
    }, [typeBreakpoint]);

    const clickSliderHandler = useCallback(
        (index) => {
            setLightboxController({
                toggler: !lightboxController.toggler,
                slide: index + 1,
            });
        },
        [lightboxController.toggler],
    );

    return (
        <SliderPhotosWrapper>
            <Slider
                slidesPerView={countSlidesPerView}
                items={photosItem}
                className="swiper-gallery"
                speed={5000}
                autoplay={{ delay: 0, disableOnInteraction: true }}
                grabCursor={true}
                freeMode={true}
                clickSliderHandler={clickSliderHandler}
            />
            <FsLightbox toggler={lightboxController.toggler} sources={photos} slide={lightboxController.slide} />
        </SliderPhotosWrapper>
    );
};

export default SliderPhotos;
