import { GREY_COLOR, WHITE_COLOR } from "@assets/styles/tokens/colors";
import { adaptive } from "@assets/styles/tokens/screen";
import { secondaryFontFamily } from "@assets/styles/tokens/typography";

import styled from "styled-components";

export const AddressSidebarWrapper = styled.div`
    flex-basis: auto;
    flex-shrink: 0;
    padding: 16px 16px 8px 16px;
    background-color: ${GREY_COLOR};

    &.active-list {
        height: 100%;
        overflow: hidden;
    }

    ${adaptive.minWidth.tablet} {
        padding: 24px 0 0 24px;
        flex-basis: 50%;
        height: auto;
    }

    ${adaptive.minWidth.desktop} {
        flex-basis: 368px;
    }
`;

export const AddressSidebarList = styled.div`
    margin-top: 23px;

    flex-grow: 1;
    overflow-y: scroll;

    padding-bottom: 12px;
    padding-right: 0;
    margin-right: 6px;

    scrollbar-width: 6px;
    scrollbar-color: #646468;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: #646468;
    }

    ${adaptive.minWidth.tablet} {
        padding-right: 16px;
    }
`;

export const AddressSidebarListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const AddressSidebarInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;

    overflow: hidden;

    height: 100%;
`;

export const AddressSidebarCurrentWrapper = styled.div``;

export const AddressSidebarToggleBtnWrapper = styled.div`
    display: flex;

    min-height: 36px;
    width: 100%;

    background: rgba(255, 255, 255, 0.15);
    border-radius: 40px;

    margin-top: 23px;

    ${adaptive.minWidth.tablet} {
        display: none;
    }
`;

export const AddressSidebarToggleBtn = styled.button`
    width: 50%;

    background-color: ${({ active }) => (active ? WHITE_COLOR : "transparent")};
    border: none;
    border-radius: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        ${secondaryFontFamily}
    }
`;
